import React from "react";
import { INotificationItem } from "./notificationsInfo";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { timeToNow } from "../../../utils";

interface NotificationItemProps {
  notification: INotificationItem;
  handleNotificationClose: (viewedNotificationIds?: number[] | number) => void;
}

export const NotificationItem = ({
  notification,
  handleNotificationClose,
}: NotificationItemProps) => {
  const { read, icon, title, description, date, textLink, link } = notification;
  return (
    <NotificationItemContainer
      id={`notification-${notification.id}`}
      read={read}
      onClick={() => handleNotificationClose(notification.id)}
    >
      <Icon src={icon}></Icon>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Footer>
          <StyledDate>{timeToNow(new Date(date))}</StyledDate>
          <StyledLink to={link}>
            {textLink}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 12H19"
                stroke="url(#paint0_linear_3740_24647)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 19L19 12L12 5"
                stroke="url(#paint1_linear_3740_24647)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3740_24647"
                  x1="18.8775"
                  y1="12"
                  x2="5.05351"
                  y2="13.4948"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#37DCF2" />
                  <stop offset="1" stopColor="#07C0FB" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_3740_24647"
                  x1="18.9388"
                  y1="5"
                  x2="11.946"
                  y2="5.02701"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#37DCF2" />
                  <stop offset="1" stopColor="#07C0FB" />
                </linearGradient>
              </defs>
            </svg>
          </StyledLink>
        </Footer>
      </Content>
    </NotificationItemContainer>
  );
};

const NotificationItemContainer = styled.div<{ read: boolean }>`
  display: flex;
  gap: 16px;
  padding: 12px;
  border-radius: 16px;
  background-color: ${({ read }) => (read ? null : "#07befb1a")};
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s;
  max-height: fit-content;
`;

const Icon = styled.img`
  max-width: 64px;
  max-height: 64px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledDate = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: 0.2s;

  &:hover {
    svg {
      transition: 0.2s;
      transform: translateX(4px);
    }
  }
`;
