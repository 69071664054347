import React, { useEffect, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { Modal } from "../../../components/Modal/Modal";
import { useAccount } from "wagmi";
import { getTokenID } from "../../../utils/LiquidityGraph";
import { ConfirmIcon } from "../../../components/Icons/ConfirmIcon";
import { formatDisplay, formatDisplayShorthand } from "../../../utils/formatDisplay";
import { Contract, ethers } from "ethers";
import { OceanABI } from "../../../constants/ABI/OceanABI";
import { OCEAN_ADDRESS } from "../../../constants/addresses";
import { parseUnits } from "ethers/lib/utils";
import { ErrorIcon } from "../../../components/Icons/ErrorIcon";
import { Spinner } from "../../../components/Loaders";
import { NFT, NFT1155, maxTokenDecimals } from "../../../placeholders/tokens";
import { tokenColors } from "../../../constants/tokenColors";
import { CONNECTED_CHAIN_EXPLORER } from "../../../constants/chains";
import toast from "react-hot-toast";
import { useWidthBreakpoint } from '../../../hooks';
import { breakpoints, Media } from "../../../styles";
import NFTSelectionModal from "../../Trade/NFTsSwiper/NFTSelectionModal";
import { NFTsSwiper } from "../../Trade/NFTsSwiper/NFTsSwiper";
import { calculateWrappedTokenId } from "../../../utils/ocean/utils";
import NFTDropDownSelect from "./NFTDropDownSelect";

interface TransferTokenProps {
  token?: any;
  collection?: any;
  balance: number;
  quota: number;
  wallet: any;
  isNFT?: boolean;
  is1155?: boolean;
  nfts?: (NFT | NFT1155 | undefined)[];
}

export const TransferToken = React.memo(({ token, collection, balance, quota, wallet, isNFT = false, is1155 = false, nfts }: TransferTokenProps) => {
  const isTablet = useWidthBreakpoint(breakpoints.tablet)

  const STEP_RESET = 0;
  const STEP_SELECT_NFT = 1;
  const STEP_TRANSFER_NFT = 2;

  const { address: walletAddress, isConnected } = useAccount();

  const [isTransferScreenVisible, setIsTransferScreenVisible] = useState(false);
  const [addressValue, setAddressValue] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [balanceError, setBalanceError] = useState(false);

  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [txState, setTxState] = useState("");
  const [txHash, setTxHash] = useState("");

  const [showNftSelectionModal, setShowNftSelectionModal] = useState(false);
  const [nftSelectionStep, setNftSelectionStep] = useState(STEP_RESET);
  const [selectedNFTs, setSelectedNFTs] = useState<{ [collection: string]: (NFT | NFT1155)[] }>({});  

  let displayDecimals;
  if (token) {
    displayDecimals = maxTokenDecimals(getTokenID(token));
  } else if (collection) {
    displayDecimals = maxTokenDecimals(collection.symbol);
  }

  const handleAmountInput = (input: string) => {
    const newBalance = is1155 ? (selectedNFTs[collection.symbol]?.[0] as NFT1155)?.balance ?? 0 : balance;
    setBalanceError(parseFloat(input) > newBalance);
    setAmountValue(input);
  };
  
  const handleUpdateSelectedNFTs = useCallback((collection: string, items: (NFT | NFT1155)[]) => {
    setSelectedNFTs((prevSelectedNFTs) => ({
      ...prevSelectedNFTs,
      [collection]: items,
    }));
    setNftSelectionStep(prevStep => {
      if (prevStep === STEP_RESET) return STEP_SELECT_NFT;
      if (prevStep === STEP_SELECT_NFT) return STEP_TRANSFER_NFT;
      return prevStep;
    });
    setIsTransferScreenVisible(true);
  }, [setSelectedNFTs, setNftSelectionStep, setIsTransferScreenVisible]);

  const handleTransferButtonClick = () => {
    if (isNFT && !is1155) {
      setNftSelectionStep(STEP_SELECT_NFT);
      setShowNftSelectionModal(true);
    } else {
      setIsTransferScreenVisible(true);
    }
  };

  const handleClose = () => {
    if (isNFT) {
      setSelectedNFTs({});
      setNftSelectionStep(STEP_RESET);
    }
    setAmountValue("");
    setBalanceError(false);
    setAddressValue("");
    setIsTransferScreenVisible(false);
    setShowNftSelectionModal(false);

    if (txState === "Success") window.location.reload();
  };

  const executeTransaction = () => {
    if (!isConnected) return;
    setConfirmDisabled(true);
    setTxState("Pending");
  
    const ocean = new Contract(OCEAN_ADDRESS, OceanABI, wallet);
    if (token) {
      ocean.safeTransferFrom(walletAddress, addressValue, token.oceanID, parseUnits(amountValue), [])
        .then(handleTransactionResponse)
        .catch(handleTransactionError);
    } else if (collection) {
      const selected1155Nft = selectedNFTs[collection.symbol]?.[0];
      if (is1155 && selected1155Nft) {
        const id = calculateWrappedTokenId(collection.address, selected1155Nft.id);
        ocean.safeTransferFrom(walletAddress, addressValue, id, parseInt(amountValue), [])
          .then(handleTransactionResponse)
          .catch(handleTransactionError);
      } else {
        const ids = selectedNFTs[collection.symbol]?.map(nft => calculateWrappedTokenId(collection.address, nft.id)) || [];
        const amounts = new Array(ids.length).fill(1);
        ocean.safeBatchTransferFrom(walletAddress, addressValue, ids, amounts, [])
          .then(handleTransactionResponse)
          .catch(handleTransactionError);
      }
    }
  };

  const handleTransactionResponse = (response: any) => {
    setTxState("Submitted");

    toast.promise(response.wait(), {
      loading: "Transfer pending",
      success: (data: any) => {
        setTxHash(data.transactionHash);
        setTxState("Success");
        return "Transfer success";
      },
      error: (data: any) => {
        setTxHash(data.transactionHash);
        setTxState("Error");
        return "Transfer error";
      },
    });
  };

  const handleTransactionError = () => {
    setTxState("");
    setConfirmDisabled(false);
  };

  useEffect(() => {
    const hasSelectedNFTs = selectedNFTs[collection?.symbol]?.length > 0;
    const disableBasedOnAddressAndError = !ethers.utils.isAddress(addressValue) || balanceError;
    const disableBasedOnCollectionType = collection
      ? (!hasSelectedNFTs || (is1155 && !amountValue)) 
      : !amountValue;
  
    setConfirmDisabled(disableBasedOnAddressAndError || disableBasedOnCollectionType);
  }, [addressValue, collection, token, selectedNFTs, amountValue, balanceError, is1155]);

  return isConnected && balance > 0 ? (
    <>
      <TransferButton onClick={handleTransferButtonClick} />
      {!isNFT && (
        <StyledModal
          title={`Transfer ${getTokenID(token)}`}
          isVisible={isTransferScreenVisible}
          onClose={handleClose}
          isNFT={isNFT}
        >
          <Label>To</Label>
          <AddressInput
            ownAddress={walletAddress?.toString() ?? ''}
            value={addressValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAddressValue(event.target.value)
            }
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Label>Amount</Label>
            <Label style={{ fontSize: '12px', fontWeight: 100 }}>{`Balance: ${formatDisplayShorthand(balance, maxTokenDecimals(getTokenID(token)))}`}</Label>
          </div>
          <div style={{ position: 'relative', marginBottom: '8px' }}>
            <StyledInput
              value={amountValue}
              onChange={event => handleAmountInput(event.target.value)}
              placeholder="0"
              error={balanceError}
            />
            <MaxButton onClick={() => setAmountValue(balance.toString())}>Max</MaxButton>
          </div>
          <AssetInfo
            asset={token}
            balance={balance}
            displayDecimals={displayDecimals}
            amountValue={amountValue}
            quota={quota}
            isCollection={false}
          />
          <TransferConfirmationButton
            name={getTokenID(token)}
            amount={amountValue}
            color={tokenColors[token.symbol]}
            addressValue={addressValue}
            txState={txState}
            txHash={txHash}
            executeTransaction={executeTransaction}
            confirmDisabled={confirmDisabled}
            CONNECTED_CHAIN_EXPLORER={CONNECTED_CHAIN_EXPLORER}
          />
        </StyledModal>
      )}

      {isNFT && is1155 && (
        <StyledModal
          title={`Transfer ${collection.symbol}`}
          isVisible={isTransferScreenVisible}
          onClose={handleClose}
          isNFT={isNFT}>
        <Label>To</Label>
        <AddressInput
          ownAddress={walletAddress?.toString() ?? ''}
          value={addressValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAddressValue(event.target.value)}
        />
        <NFTDropDownSelect
          collection={collection.symbol}
          nfts={nfts}
          updateSelectedNFTs={handleUpdateSelectedNFTs}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Label>Amount</Label>
          <Label style={{ fontSize: '12px', fontWeight: 100 }}>
            {`Balance: ${formatDisplayShorthand((selectedNFTs[collection.symbol]?.[0] as NFT1155)?.balance ?? 0, maxTokenDecimals(collection.symbol))}`}
          </Label>
        </div>
        <div style={{ position: 'relative', marginBottom: '8px' }}>
          <StyledInput
            value={amountValue}
            onChange={event => handleAmountInput(event.target.value)}
            placeholder="0"
            error={balanceError}
          />
          <MaxButton onClick={() => setAmountValue(((selectedNFTs[collection.symbol]?.[0] as NFT1155)?.balance ?? 0).toString())}>Max</MaxButton>
        </div>
        <AssetInfo
          asset={collection}
          balance={(selectedNFTs[collection.symbol]?.[0] as NFT1155)?.balance ?? 0}
          amountValue={amountValue}
          displayDecimals={displayDecimals}
          selectedNFTs={selectedNFTs}
          quota={quota}
          isCollection={true}
          is1155={true}
        />
        <TransferConfirmationButton
          name={(selectedNFTs[collection.symbol]?.[0] as NFT1155)?.name}
          amount={selectedNFTs[collection.symbol]?.length + "" || "0"}
          color={tokenColors[collection.symbol]}
          addressValue={addressValue}
          txState={txState}
          txHash={txHash}
          executeTransaction={executeTransaction}
          confirmDisabled={confirmDisabled}
          CONNECTED_CHAIN_EXPLORER={CONNECTED_CHAIN_EXPLORER}
        />
      </StyledModal>
      )}

      {nftSelectionStep === STEP_SELECT_NFT && isNFT && !is1155 && (
        <NFTSelectionModal
          title={"Select NFTs to Send"}
          allSelectedNFTs={selectedNFTs}
          updateSelectedNFTs={handleUpdateSelectedNFTs}
          isInputToken={true}
          selectedCollection={collection}
          showModal={showNftSelectionModal}
          setShowModal={setShowNftSelectionModal}
          onClose={handleClose}
        />
      )}
      {nftSelectionStep === STEP_TRANSFER_NFT && isNFT && !is1155 && (
        <StyledModal
          title={`Transfer ${collection.symbol}`}
          isVisible={isTransferScreenVisible}
          onClose={handleClose}
          isNFT={isNFT}
        >
          <Label>To</Label>
          <AddressInput
            ownAddress={walletAddress?.toString() ?? ''}
            value={addressValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAddressValue(event.target.value)
            }
          />
          {selectedNFTs[collection.symbol] &&
            selectedNFTs[collection.symbol].length > 0 && (
              <>
                <Row style={{ margin: "0px 0px 8px 0px" }}>
                  <Label>Selected NFTs</Label>
                </Row>
                <SwiperContainer>
                  <NFTsSwiper
                    isConfirmation={true}
                    nftTokens={selectedNFTs[collection.symbol]}
                    navitagionTransform={{ left: "0", right: "0" }}
                  />
                </SwiperContainer>
              </>
            )}
          <AssetInfo
            asset={collection}
            balance={balance}
            displayDecimals={displayDecimals}
            selectedNFTs={selectedNFTs}
            quota={quota}
            isCollection={true}
          />
          <TransferConfirmationButton
            name={collection.name}
            amount={selectedNFTs[collection.symbol]?.length + "" || "0"}
            color={tokenColors[collection.symbol]}
            addressValue={addressValue}
            txState={txState}
            txHash={txHash}
            executeTransaction={executeTransaction}
            confirmDisabled={confirmDisabled}
            CONNECTED_CHAIN_EXPLORER={CONNECTED_CHAIN_EXPLORER}
          />
        </StyledModal>
      )}
    </>
  ) : null;
});

const TransferButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <TransferIcon onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
    </svg>
  </TransferIcon>
);

const AddressInput: React.FC<{
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ownAddress: string;
}> = ({ value, onChange, ownAddress }) => {
  const disableConfirm = value.toLowerCase() === ownAddress.toLowerCase();

  return (
    <div style={{ position: "relative", marginBottom: "8px" }}>
      <StyledInput value={value} onChange={onChange} placeholder="0x..." />
      {ethers.utils.isAddress(value) && !disableConfirm && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          color="#34b223"
          style={{
            position: "absolute",
            right: "0px",
            top: "12px",
            width: "18px",
          }}
        >
          <path
            d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
            fill="currentColor"
          />
        </svg>
      )}
      {disableConfirm && (
        <div style={{ marginTop: '8px', color: "red", fontSize: "12px"}}>
          You cannot transfer tokens to your own wallet.
        </div>
      )}
    </div>
  );
};

const AssetInfo: React.FC<{
  asset: any;
  balance: number;
  displayDecimals?: number;
  amountValue?: string;
  selectedNFTs?: any;
  quota: number;
  isCollection: boolean;
  is1155?: boolean;
}> = ({ asset, balance, displayDecimals, amountValue = "", selectedNFTs = {}, quota, isCollection, is1155}) => {
  const assetKey = isCollection && !is1155 ? asset.symbol : isCollection && is1155 ? selectedNFTs[asset.symbol]?.[0]?.name || asset.symbol : getTokenID(asset);
  const assetIcon = isCollection ? asset.icon : asset.icon;
  const deductedBalance = isCollection && !is1155
    ? balance - (selectedNFTs[asset.symbol]?.length || 0)
    : balance - parseFloat(amountValue == "" ? "0" : amountValue);

  const getQuotaColor = (newBalance: number) => {
    if (balance >= quota && newBalance < quota) {
      return "red";
    } else {
      return "white";
    }
  };

  return (
    <>
      <Label>{quota > 0 ? "Balance / Quota" : "Balance"}</Label>
      <Row key={assetKey} style={{ margin: "8px 0 8px 0" }}>
        <Value style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ maxHeight: "24px", maxWidth: "24px" }}
            src={assetIcon}
            alt="logo"
          />
          {assetKey}
        </Value>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Value>{formatDisplayShorthand(balance, displayDecimals)}</Value>
          <Quota color={getQuotaColor(deductedBalance)}>
            {"\u2192"}{" "}
            {formatDisplayShorthand(
              Math.max(deductedBalance, 0),
              displayDecimals
            )}{" "}
            {quota > 0 && `/ ${formatDisplayShorthand(quota, displayDecimals)}`}
          </Quota>
        </div>
      </Row>
    </>
  );
};

const TransferConfirmationButton: React.FC<{
  name: string;
  amount: string;
  color: string;
  addressValue: string;
  txState: string;
  txHash: string;
  executeTransaction: () => void;
  confirmDisabled: boolean;
  CONNECTED_CHAIN_EXPLORER: string;
}> = ({ name, amount, color, addressValue, txState, txHash, executeTransaction, confirmDisabled, CONNECTED_CHAIN_EXPLORER}) => {
  return (
    <>
      {((!confirmDisabled && txState == "") || txState == "Pending" || txState == "Submitted") && (
        <Summary>
          <SummaryText>
            Transferring
            {
              <span>
                <SummaryColor color={color}>
                  {` ${formatDisplay(amount)} ${name} `}
                </SummaryColor>
              </span>
            }
            to
            <Shimmer>{" " + addressValue}</Shimmer>
          </SummaryText>
        </Summary>
      )}
      {txState && txState != "Pending" && (
        <>
          {txState != "Submitted" && (
            <TxState style={{ margin: "0 auto 12px auto", color: "#7D7D97" }}>
              {txState == "Success" ? "Success!" : "Error"}
            </TxState>
          )}
          <CircleContainer>
            {txState == "Success" ? (
              <ConfirmIcon />
            ) : txState == "Error" ? (
              <ErrorIcon />
            ) : (
              <Spinner />
            )}
          </CircleContainer>
          {txState != "Submitted" && (
            <ExplorerLink
              href={CONNECTED_CHAIN_EXPLORER + "tx/" + txHash}
              target="_blank"
            >{`View in Explorer \u2192`}</ExplorerLink>
          )}
        </>
      )}
      {(txState == "" || txState == "Pending") && (
        <ConfirmButton onClick={executeTransaction} disabled={confirmDisabled}>
          <ConfirmIcon />
          Confirm Transfer
        </ConfirmButton>
      )}
      {txState == "Submitted" && (
        <TxState style={{ margin: "8px auto 2px auto", color: "#7D7D97" }}>
          Waiting for blockchain confirmation...
        </TxState>
      )}
    </>
  );
};

const StyledModal = styled(Modal)<{ isNFT?: boolean }>`
  height: fit-content;

  ${Media.mobile} {
    height: 85%;
    max-width: 100%;
    margin-top: 15.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const SwiperContainer = styled.div`
  display: flex;
  position: relative;

  ${Media.mobile} {
    margin-bottom: 18px;
  }
`;

const CircleContainer = styled.div`
  width: 48px;
  height: 48px;
  > img {
    width: 48px;
    height: 48px;
  }
  color: #00BDFF;
  margin: auto;
`

const TransferIcon = styled.button`
  /* width: 36px;
  height: 36px;
  margin-right: 8px; */

  &:hover {
    opacity: 0.7;
  }

  > svg {
    fill: #00BDFF;
    //margin-top: 4px;
  }
`;

const Label = styled.p`
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #7D7D97;
  white-space: nowrap;
  letter-spacing: -0.02em;
`;

const inputTextStyles = css`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
`

const placeholderStyles = css`
  ${inputTextStyles}
  color: #7D7D97;
`

export const StyledInput = styled.input<{ error? : boolean}>`
  width: 100%;
  padding: 12px 0 8px 4px;
  border: none;
  border-bottom: 1px solid #7D7D97;
  background: left center no-repeat;
  outline: none;
  ${inputTextStyles}

  &:focus {
    border-color: #FFFFFF;
  }

  color: ${({ error }) => error ? '#cc0000' : '#FFFFFF'};

  &::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  &::-moz-placeholder {
    ${placeholderStyles}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}
  }
  &:-moz-placeholder {
    ${placeholderStyles}
  }
`;

const MaxButton = styled.button`
  position: absolute;
  right: 0px;
  top: 11px;
  border: none;
  border: solid 1px #7D7D97;
  border-radius: 4px;
  background: rgba(255, 255, 255,0);
  color: #7D7D97;
  font-size: 10px;
  font-weight: 250;
  line-height: 17px;

  :hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    color: #000E47;
    border: solid 1px #000E47;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
`;

const Value = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #FFFFFF;
  white-space: nowrap;
  letter-spacing: 0.02em;
`;

const Quota = styled.p<{color? : string}>`
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #FFFFFF;
  white-space: nowrap;
  margin-left: 4px;
  letter-spacing: 0.02em;
  ${({ color }) => color === 'red' && css`
    color: #FF5349;
  `};
`;

const TxState = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #7D7D97;
  white-space: nowrap;
  letter-spacing: -0.02em;
`;

const Summary = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`

const SummaryText = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-weight: 100;
`

const SummaryColor = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-weight: 400;
`

const Shimmer = styled.span`
    color: rgba(255,255,255,0.1);
    background: -webkit-gradient(linear, left top, right top, from(#00BDFF), to(#00BDFF), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#00BDFF), to(#00BDFF), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#00BDFF), to(#00BDFF), color-stop(0.5, #fff));
    -webkit-background-size: 85%;
    -moz-background-size: 85%;
    background-size: 85%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 2.5s;
    -moz-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #00BDFF;

    @keyframes shimmer {
        0% {
            background-position: -200px 0;
        }
        100% {
            background-position: 300px 0;
        }
    }
`

const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #151530;
    color: #464659;
  }

  ${Media.tablet} {
    height: 66px;
    margin: 32px auto;
  }
`;

const ExplorerLink = styled.a`
    margin-top: 12px;
    font-weight: 100;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #7D7D97;
    white-space: nowrap;
    letter-spacing: -0.02em;

    :hover {
        color: #00BDFF;
    }
`