import { isNFTCollection, NFT, Token, tokenMap } from "../placeholders/tokens";
import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256, HashZero } from "@ethersproject/constants"
import { parseUnits } from "@ethersproject/units"
import { findPath } from './testRouter';
import { Interaction, InteractionCode } from "./ocean/types";
import { getTokenID } from "./LiquidityGraph";
import { calculateWrappedTokenId, unpackInteractionTypeAndAddress } from "./ocean/utils";
import { hexZeroPad, hexlify, hexStripZeros } from "ethers/lib/utils";

const wrapCodes = new Set([
    InteractionCode.Erc20Wrap, 
    InteractionCode.Erc1155Wrap, 
    InteractionCode.Erc721Wrap, 
    InteractionCode.EtherWrap
].map((code) => code.toString()))

const unwrapCodes = new Set([
    InteractionCode.Erc20Unwrap, 
    InteractionCode.Erc1155Unwrap, 
    InteractionCode.Erc721Unwrap, 
    InteractionCode.EtherUnwrap
].map((code) => code.toString()))

const opcodes721 = new Set([
    InteractionCode.Erc721Wrap,
    InteractionCode.Erc721Unwrap, 
].map((code) => code.toString()))

const opcodes1155 = new Set([
    InteractionCode.Erc1155Wrap,
    InteractionCode.Erc1155Unwrap, 
].map((code) => code.toString()))

export const buildInteractions = (
    inputTokens: Token[],
    outputTokens: Token[],
    specifiedAmounts: Record<string, string>,
    expectedAmounts: {[tokenOne: string] : {[tokenTwo: string] : BigNumber}}, // Input or output amounts adjusted for slippage tolerance
    fromInputs: boolean,
    splitAmounts: Record<string, BigNumber>,
    selectedNFTs: {[collection: string] : NFT[]},
): Interaction[] => {
    const split = Object.keys(splitAmounts).length > 0

    const [multiTokens, singleToken] = fromInputs == split ? [outputTokens, inputTokens[0]] : [inputTokens, outputTokens[0]]

    const paths : Interaction[][] = multiTokens.map(token => getTokenID(token)).map((name) => {
        // Use tokens that user specified amount for as start
        const start = split ? getTokenID(singleToken) : name
        const end = split ? name : getTokenID(singleToken)
        return findPath(start, end, fromInputs)
    }).map((path, index) => {

        if (path !== undefined) {

            const resolvedInteractions = []
        
            for(let i = 0; i < path.interactions.length; i++){
                if(isNFTCollection(path.tokens[i])){
                    resolvedInteractions.push(
                        path.interactions[i](
                            path.tokens[i].is1155 ? parseFloat(specifiedAmounts[getTokenID(path.tokens[i])]) : 1, 
                            fromInputs, 
                            '', 
                            stringToHex(path.tokens[i].symbol)
                        )
                    )

                    if(i < path.interactions.length - 1 && isNFTCollection(path.tokens[i + 1])){ // Propogate NFT IDs to next step if applicable
                        selectedNFTs[path.tokens[i + 1].symbol] = Array.from(
                            new Set(selectedNFTs[path.tokens[i].symbol].concat(selectedNFTs[path.tokens[i + 1].symbol] ?? []))
                        )
                        if(path.tokens[i + 1].is1155 && (!split || index == 0)){
                            specifiedAmounts[getTokenID(path.tokens[i + 1])] = (
                                parseInt(specifiedAmounts[getTokenID(path.tokens[i])]) + 
                                parseInt(specifiedAmounts[getTokenID(path.tokens[i + 1])] ?? '0')
                            ).toString()
                        }
                    }
                } else {

                    if(i == 0){
                        resolvedInteractions.push(
                            path.interactions[0](split ? 
                                splitAmounts[getTokenID(multiTokens[index])] : parseUnits(specifiedAmounts[getTokenID(path.tokens[i])]), 
                            fromInputs)
                        )
                    } else {
                        resolvedInteractions.push(path.interactions[i](MaxUint256, fromInputs))
                    }

                }
            }

            const tokenOne = getTokenID(split ? multiTokens[index] : singleToken)
            const tokenTwo = getTokenID(split ? singleToken : multiTokens[index])

            for(let i = resolvedInteractions.length - 1; i >= 0; i--){ // Apply slippage to last compute step
                
                if(resolvedInteractions[i].metadata != HashZero) continue // skip NFT interactions

                const interactionCode = unpackInteractionTypeAndAddress(resolvedInteractions[i].interactionTypeAndAddress).interactionType
                if(interactionCode == InteractionCode.ComputeOutputAmount || interactionCode == InteractionCode.ComputeInputAmount){
                    resolvedInteractions[i].metadata = hexZeroPad(hexlify(expectedAmounts[tokenOne][tokenTwo]), 32)
                    break;
                }
            }

            return resolvedInteractions

        } else {
            throw new Error("Couldn't resolve interactions");
        }
    })

    let interactions
    
    if (paths.length === 1) {
      interactions = paths[0]
    } else if (paths.length === 2) {
      if(split)
        interactions = combineSplit(
            paths, 
            parseUnits(specifiedAmounts[getTokenID(singleToken)]), 
            Object.values(splitAmounts), 
            fromInputs ? wrapCodes : unwrapCodes, 
            new Set(Object.keys(selectedNFTs)
        ))
      else 
        interactions = combineMerge(paths, new Set(Object.keys(selectedNFTs)))
    } else {
      throw new Error("Unexpected number of interaction arrays to merge")
    }
    return resolveNFTInteractions(interactions, selectedNFTs, fromInputs)
}

const combineSplit = (paths: Interaction[][], specifiedAmount: BigNumber, splitAmounts: BigNumber[], opcodes : Set<string>, nfts : Set<string>): Interaction[] => {

    paths = paths.sort((a, b) => b.length - a.length)

    if(unpackInteractionTypeAndAddress(paths[1].at(-1)!.interactionTypeAndAddress).interactionType == InteractionCode.EtherWrap)
        paths.reverse()

    const preInteractions = []

    while(true){
        const first = paths[0][0]
        const second = paths[1][0]

        if(compareInteractions(first, second)){
            if(opcodes.has(unpackInteractionTypeAndAddress(first.interactionTypeAndAddress).interactionType) || isNFTInteraction(first, nfts)){
                paths[0].shift()
                paths[1].shift()
                if(preInteractions.length == 0 && !isNFTInteraction(first, nfts))
                    first.specifiedAmount = specifiedAmount
                preInteractions.push(first)
            } else {
                break
            }
        } else {
            break
        }
    }

    for(let i = 0; i < paths.length; i++){
        if(paths[i][0] && paths[i][0].specifiedAmount == MaxUint256){
            paths[i][0].specifiedAmount = splitAmounts[i]
        }
    }

    return preInteractions.concat(paths.flat())
}

const combineMerge = (paths: Interaction[][], nfts : Set<string>): Interaction[] => {

  let mergedInteractions = []

  while (paths[0].length || paths[1].length) {
    const first = paths[0].pop()
    const second = paths[1].pop()

    if (first && second) {
      if (compareInteractions(first, second)) {
        if (
          !BigNumber.from(first.specifiedAmount).eq(MaxUint256) &&
          !BigNumber.from(second.specifiedAmount).eq(MaxUint256)
        ) {
          if(!isNFTInteraction(first, nfts)){
            first.specifiedAmount = BigNumber.from(first.specifiedAmount).add(second.specifiedAmount)
            first.metadata = hexZeroPad(hexlify(BigNumber.from(first.metadata).add(second.metadata)), 32) // merge slippage
          }
          mergedInteractions.push(first)
        } else if (
          BigNumber.from(first.specifiedAmount).eq(MaxUint256) &&
          BigNumber.from(second.specifiedAmount).eq(MaxUint256)
        ) {
          first.metadata = hexZeroPad(hexlify(BigNumber.from(first.metadata).add(second.metadata)), 32) // merge slippage
          mergedInteractions.push(first)
        } else if (BigNumber.from(first.specifiedAmount).eq(MaxUint256)) {
          // this is in reverse cause we call reverse later on
          mergedInteractions.push(second)
          mergedInteractions.push(first)
        } else {
          mergedInteractions.push(first)
          mergedInteractions.push(second)
        }
      } else {
        // this is in reverse cause we call reverse later on
        mergedInteractions.push(second)
        mergedInteractions.push(first)
      }
    } else {
      if (first) mergedInteractions.push(first)
      if (second) mergedInteractions.push(second)
    }
  }

  mergedInteractions.reverse()
  return mergedInteractions
}

const resolveNFTInteractions = (interactions: Interaction[], selectedNFTs : {[collection: string] : NFT[]}, fromInputs : boolean) => {
    const resolvedInteractions: Interaction[] = []
    const nfts = new Set(Object.keys(selectedNFTs))
    interactions.forEach((interaction) => {
        if(isNFTInteraction(interaction, nfts)){
            const collection = tokenMap[hexStringToString(interaction.metadata)]
            if(isNFTCollection(collection) && collection.is1155){
                const item = selectedNFTs[collection.symbol][0]
                let interactionCopy = {...interaction}
                interactionCopy.metadata = hexZeroPad(hexlify(item.id), 32)
                if(!opcodes1155.has(unpackInteractionTypeAndAddress(interaction.interactionTypeAndAddress).interactionType)){
                    const tokenID = calculateWrappedTokenId(collection.address, item.id)
                    if(fromInputs){
                        interactionCopy.inputToken = tokenID
                    } else{
                        interactionCopy.outputToken = tokenID
                    }
                } 
                resolvedInteractions.push(interactionCopy)
            } else {
                selectedNFTs[collection.symbol].forEach((nft) => {
                    let interactionCopy = {...interaction}
                    interactionCopy.metadata = hexZeroPad(hexlify(nft.id), 32)
                    if(!opcodes721.has(unpackInteractionTypeAndAddress(interaction.interactionTypeAndAddress).interactionType)){
                        const tokenID = calculateWrappedTokenId(collection.address, nft.id)
                        if(fromInputs){
                            interactionCopy.inputToken = tokenID
                        } else{
                            interactionCopy.outputToken = tokenID
                        }
                    } 
                    resolvedInteractions.push(interactionCopy)
                })
            }
        } else {
            resolvedInteractions.push(interaction)
        }
    })
    return resolvedInteractions
}

const isNFTInteraction = (interaction: Interaction, nfts: Set<string>) => {
    return nfts.has(hexStringToString(interaction.metadata))
}

const compareInteractions = (lhs: Interaction, rhs: Interaction): boolean => {
  if(lhs == null || rhs == null) return false
  if (lhs.interactionTypeAndAddress != rhs.interactionTypeAndAddress) return false
  if (!BigNumber.from(lhs.inputToken).eq(rhs.inputToken)) return false
  if (!BigNumber.from(lhs.outputToken).eq(rhs.outputToken)) return false
  return true
}

const stringToHex = (str : string) => {
    let hex = "";
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i).toString(16);
      hex += charCode.padStart(2, "0"); // Ensure two-digit hex representation
    }
    return '0x' + hex
  }

const hexStringToString = (hexString : string) => {
    const cleanHexString = hexStripZeros(hexString).replace(/^0x/, "");
    const bytePairs = cleanHexString.match(/.{2}/g);
    const originalString = bytePairs?.map(pair => String.fromCharCode(parseInt(pair, 16))).join("") ?? '';
    return originalString
}  