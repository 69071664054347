import React, { useRef, useState } from "react";
import styled from "styled-components";
import { DropdownToggle, Dropdown, DropdownContent, DropdownButton, } from "../../../components/Dropdown";
import { DropdownIconDown, DropdownIconUp, } from "../../../components/Icons/DropdownIcon";
import checked from "../../../assets/icons/dropdown-checked.svg";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { useToggler } from "../../../hooks/useToggler";
import { NFT, NFT1155 } from "../../../placeholders/tokens";

interface NFTSelectionProps {
  collection: string;
  nfts?: (NFT | NFT1155 | undefined)[];
  updateSelectedNFTs: (collection: string, items: (NFT | NFT1155)[]) => void;
}

const NFT1155DropDown: React.FC<NFTSelectionProps> = ({ collection, nfts, updateSelectedNFTs, }) => {
  const { visible, toggle } = useToggler();
  const ref = useRef(null);
  useClickOutside(ref, () => toggle(false), [toggle]);

  const [selectedNFT, setSelectedNFT] = useState<NFT | NFT1155 | undefined>(undefined);

  const handleNFTSelect = (nft: NFT | NFT1155) => {
    updateSelectedNFTs(collection, [nft]);
    setSelectedNFT(nft);
    toggle(false);
  };

  return (
    <StyledDropdown ref={ref} className={visible ? "expanded" : ""}>
    <StyledDropdownToggle onClick={() => toggle()}>
      {selectedNFT ? ( 
        <DropDownToggleSelectedNFT>
          <img src={selectedNFT.image} alt={selectedNFT.name} />
          {selectedNFT.name}
        </DropDownToggleSelectedNFT>
      ) : `Select ${collection}`}
      {visible ? <DropdownIconUp /> : <DropdownIconDown />}
    </StyledDropdownToggle>
      {visible && (
        <StyledDropdownContent>
          {nfts?.map((nft) => (
            <li key={nft?.id}>
              {nft !== undefined && (
                <StyledDropdownButton onClick={() => handleNFTSelect(nft)}>
                  <img src={nft?.image} alt={nft?.name} width="20" height="20"/>
                  {nft?.name}
                </StyledDropdownButton>
              )}
            </li>
          ))}
        </StyledDropdownContent>
      )}
    </StyledDropdown>
  );
};

export default NFT1155DropDown;

const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 9px 12px;
  background-color: #171B33;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 17px;

  &.active {
    border: 1px solid #fff;
  }
`;

const DropDownToggleSelectedNFT = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 5px;
  }

  span {
    font-size: 14px;
    line-height: 17px;
    color: #fff;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 8px;

  &.expanded ${StyledDropdownToggle} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const StyledDropdownContent = styled(DropdownContent)`
  padding: 6px;
  background-color: #171B33;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  bottom: 0px;

  max-height: 200px;
  overflow-y: auto;
  
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin-right: 10px;
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #1E2239;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #2C5173;
    border-radius: 5px;
  }
`;

const StyledDropdownButton = styled(DropdownButton)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 9px 12px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;

  img {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }

  &:after {
    display: none;
    position: absolute;
    content: url(${checked});
    right: 6px;
    top: 10px;
  }

  &:hover {
    background-color: #1e2239;
  }

  &.active:after {
    display: block;
  }
`;