import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components';
import { Composition, List } from "@/components/Composition/Composition";
import { Item } from "@/components/Composition/CompositionItem";
import { SkeletonBox } from "@/components/Loaders/SkeletonBox";
import { isShellToken, tokenMap } from '../../../placeholders/tokens';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addPool } from '../../../store/poolsSlice';
import { formatDisplay, formatDisplayShorthand } from '../../../utils/formatDisplay';
import { POOL_API } from '../../../constants/urls';
import { TransferToken } from './TransferToken';
import { getTokenID } from '../../../utils/LiquidityGraph';
import { extract1155Data } from '../../../utils/nftHelpers';
import { LinkButton } from "@/components/Buttons/LinkButton";
import { getSlugForStatisticsPage } from '@/pages/Pools/PoolsTable';

interface TableSubRowProps {
  row: any;
}

export const generateBuySellTokens = (tokenData : any, buy : boolean) => {
  if(buy){
      if(isShellToken(tokenData)){
          return { initInput: tokenData.tokens.map(tokenID => tokenMap[tokenID]), initOutput: [tokenData]  }
      } else {
          return { initInput: [tokenMap['ETH']], initOutput: [tokenData]  }
      }
  } else {
      if(isShellToken(tokenData)){
          return { initInput: [tokenData], initOutput: tokenData.tokens.map(tokenID => tokenMap[tokenID]) }
      } else {
          return { initInput: [tokenData], initOutput: [tokenMap['ETH']]  }
      }
  }
}

export const PoolSubRow = React.memo(({ row }: TableSubRowProps) => {
  const { original } = row;

  const [analytics, setAnalytics] = useState<any>({});
  const [userBalance, setUserBalance] = useState(0);

  const [analyticsLoading, setAnalyticsLoading] = useState(true);

  const poolData = useAppSelector((state) => state.pools.pools);
  const userBalances = useAppSelector((state) => state.balances.balances);
  const userQuotas = useAppSelector((state) => state.quotas.quotas);
  const dispatch = useAppDispatch();

  const lpToken: any = tokenMap[original.name];

  const compositionList = useMemo(() => {
    return Object.keys(analytics?.breakdown || {}).map((childToken) => {
      return {
        symbol: childToken,
        percentage: `${analytics.breakdown[childToken]}%`,
      };
    });
  }, [analytics?.breakdown]);

  const getPoolAnalytics = async (poolName: string, signal: AbortSignal) => {
    const cachedData = poolData[poolName];
    if (cachedData) {
      return cachedData;
    }

    const response = await fetch(POOL_API + poolName, { signal });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    dispatch(addPool({ name: poolName, data }));

    return data;
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchPoolInfo = async (poolName: string) => {
      try {
        const analytics = await getPoolAnalytics(poolName, signal);
        if (isMounted) {
          setAnalytics(analytics);
        }
      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      } finally {
        if (isMounted) {
          setAnalyticsLoading(false);
        }
      }
    };

    fetchPoolInfo(original.name);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [original.name, poolData]);

  useEffect(() => {
    const userBalance = userBalances[lpToken.oceanID];
    const userBalanceFormatted = formatDisplay(userBalance, original.balance.decimals);
    setUserBalance(userBalanceFormatted.includes("<") ? 0 : parseFloat(userBalanceFormatted));
  }, [userBalances[lpToken.oceanID], lpToken.oceanID]);

  return (
    <>
      <Main>
        <MainRow>
          <TokenBalances>
            {analyticsLoading
              ? <TokenRowSkeleton length={2} />
              : analytics &&
                analytics.breakdown &&
                Object.keys(analytics.breakdown).map((childToken) => {
                  const data1155 = extract1155Data(childToken);
                  const displayName = data1155 && data1155.item ? data1155.item.name : tokenMap[childToken] ? tokenMap[childToken].name : "";
                  const displayIcon = data1155 && data1155.item ? data1155.item.icon : tokenMap[childToken] ? tokenMap[childToken].icon : "";
                  return (
                    <TokenRow key={childToken}>
                        <img style={{maxHeight: '24px', maxWidth: '24px', marginRight: '8px', borderRadius: '4px'}} src={displayIcon} alt="logo" />
                        <TagRow>
                            <TokenName>{displayName}</TokenName>
                            <Value style={{fontWeight: 100}}>
                                {formatDisplay((analytics.balances[childToken] / (data1155 ? 100 : 1)).toString(), 4)}
                            </Value>
                        </TagRow>
                    </TokenRow>
                )
            })}
          </TokenBalances>
          <CompositionWrapper>
            {analyticsLoading ? (
              <CompositionSkeleton length={2} />
            ) : (
              <Composition list={compositionList} />
            )}
          </CompositionWrapper>
        </MainRow>
      </Main>
      <Footer>
        <FooterRow>
          <StatsRow>
            {analyticsLoading ? ( 
              <> <StatsSkeleton length={4} /> </>
            ) : (
              <>
                <StatsItem
                  label="APY"
                  value={`${ analytics?.apy > 1 ? formatDisplay(((analytics.apy - 1) * 100).toString(), 2) : "--" }%`}
                  color={"#7ADEB9"}
                />
                <StatsItem
                  label="Fee Tier"
                  value={`${lpToken.fee}%`}
                  color={"#00BDFF"}
                />
                <StatsItem
                  label="TVL"
                  value={`$${formatDisplayShorthand(analytics?.totalValueLocked ?? 0 )}`}
                />
                <StatsItem
                  label="24h Vol"
                  value={`$${formatDisplayShorthand(analytics["24HrVolume"] ?? 0 )}`}
                />
              </>
            )}
          </StatsRow>
          <ButtonRow>
            <ViewPoolStatsButton
              to={getSlugForStatisticsPage(lpToken)}
              outline={true}
              arrow={true}
              label="View Pool Stats"
            />
            <ProvideLPButton
              to="/trade"
              outline={false}
              arrow={true}
              label="Provide LP"
              textColor="#0B1639"
              state={generateBuySellTokens(lpToken, true)}
              onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }
            />
            <TransferToken
              token={lpToken}
              balance={userBalance}
              quota={parseFloat(userQuotas[getTokenID(lpToken)])}
              wallet={original.wallet}
            />
          </ButtonRow>
        </FooterRow>
      </Footer>
    </>
  );
});

export const TokenRowSkeleton: React.FC<{ length: number; isMobile?: boolean; }> = ({ length, isMobile }) => (
  <>
    {Array.from({ length }).map((_, index) => (
      <TokenRow key={index}>
        <SkeletonBox isLoading width="24px" height="24px" borderRadius="50%" style={{ marginRight: "8px", marginLeft: "4px"}} />
        {isMobile 
          ? <SkeletonBox isLoading width="100%" height="14px" borderRadius="4px" /> 
          : <TagRow>
              <SkeletonBox isLoading width="100%" height="14px" borderRadius="4px" />
            </TagRow>
        }
      </TokenRow>
    ))}
  </>
);

export const CompositionSkeleton: React.FC<{ length: number; }> = ({ length }) => (
  <List>
    {Array.from({ length }).map((_, index) => (
      <Item key={index} percentage="20">
        <SkeletonBox isLoading={true} width="100%" height="14px" borderRadius="4px" style={{ marginBottom: '10px' }} />
        <SkeletonBox isLoading={true} width="80px" height="14px" borderRadius="4px" style={{ marginBottom: '10px', textAlign: 'center', verticalAlign: 'top' }} />
      </Item>
    ))}
  </List>
);

export const StatsSkeleton: React.FC<{ length: number; boxWidth?: number; noMargin?: boolean; }> = ({ length, boxWidth, noMargin }) => (
  <>
    {Array.from({ length }).map((_, index) => (
      <StatsBlock key={index}>
        <SkeletonBox isLoading width={`${boxWidth ?? 80}px`} height="14px" borderRadius="4px" style={{ marginRight: noMargin ? '0' : '12px' }} />
      </StatsBlock>
    ))}
  </>
);

export const StatsItem: React.FC<{ label: string; value: React.ReactNode; color?: string; }> = ({ label, value, color }) => (
  <StatsBlock>
    <Label>{label}:</Label>
    <Value color={color} style={{marginRight: '12px'}}>{value}</Value>
  </StatsBlock>
)

const Main = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid #1E2239;
`;

const MainRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TokenBalances = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const TokenRow = styled.div`
  display: flex;
  align-items: center;
`;

const TokenName = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: #A9D1E2;
`;

const Footer = styled.div`
  padding: 16px 20px;
`;

const FooterRow = styled.div`
  display: flex;
  align-items: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

const StatsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  //gap: 12px;
  justify-items: start;
  align-items: center;
  flex: 1;
`;

const StatsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #7D7D97;
`;

const Value = styled.div<{color? : string}>`
  margin-left: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => color ?? '#FFFFFF'};
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const ProvideLPButton = styled(LinkButton)`
  height: 48px;
  box-sizing: border-box;
`;

const ViewPoolStatsButton = styled(LinkButton)`
  height: 48px;
  box-sizing: border-box;
`;

const TagRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 256px;
`;

const CompositionWrapper = styled.div`
  max-width: 50%;
  width: 100%;
`;
