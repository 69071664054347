import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useNetwork } from "wagmi";
import toast, { Toaster } from "react-hot-toast";
import arbitrumLogo from "../../assets/icons/arbitrum.svg";
import error from "../../assets/icons/error.svg";
import warningIcon from "../../assets/icons/warning.svg";
import { CONNECTED_CHAIN } from "../../constants/chains";
import { useWidthBreakpoint } from "../../hooks";
import { breakpoints, Media } from "../../styles";
import { ButtonPrimary } from "../Buttons/Button";

export const Network = () => {
  const { chain: activeChain } = useNetwork();
  const [warning, setWarning] = useState(false);
  const isTablet = useWidthBreakpoint(breakpoints.tablet);

  const changeNetwork = () => {
    window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0xA4B1"}],
    }).catch((switchError : any) => {
        if (switchError.code === 4902) {
            window.ethereum?.request({
                method: 'wallet_addEthereumChain',
                params: [{ 
                    chainId: '0xA4B1', 
                    chainName:'Arbitrum One',
                    nativeCurrency: {
                        name: 'Arbitrum ETH',
                        symbol: 'ETH',
                        decimals: 18
                    },
                    rpcUrls:['https://arb1.arbitrum.io/rpc'],
                    blockExplorerUrls:['https://arbiscan.io/'],  
                }]
            });
        }
    })
  };

  useEffect(() => {
    if (activeChain !== undefined && activeChain.unsupported) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  }, [activeChain]);

  return (
    <>
      {" "}
      <NetworkIndicator warning={warning}>
        <Toaster />
        <ArbitrumLogo
          warning={warning}
          src={warning ? warningIcon : arbitrumLogo}
          alt={warning ? "Error" : "Arbitrum Logo"}
        />
        {!isTablet && (warning ? `Wrong Chain` : CONNECTED_CHAIN)}
      </NetworkIndicator>
      {warning ? (
        <WarningInfoBanner>
          <img
            src={warningIcon}
            alt={"Error"}
            style={{ width: `32px`, height: "32px" }}
          />
          <TextInfo>
            Please switch your wallet's network to <span>{CONNECTED_CHAIN}</span>
          </TextInfo>
          <ConnectButton onClick={changeNetwork}>Switch Network</ConnectButton>
        </WarningInfoBanner>
      ) : null}
    </>
  );
};

const WarningInfoBanner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 12px));
  width: 370px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: linear-gradient(#0a0e27, #0a0e27) padding-box,
    linear-gradient(to right, #37dcf2, #07c0fb) border-box;
  border: 1px solid transparent;
  border-radius: 12px;
  z-index: 9999;

  ${Media.tablet} {
    width: 343px;
  }
`;

const TextInfo = styled.div`
  max-width: 271px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.36em;
  color: #ffffff;
  text-align: center;
  span {
    font-weight: 600;
    background: linear-gradient(#37dcf2 100%, #07c0fb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const ConnectButton = styled(ButtonPrimary)`
  width: 100%;
  height: 51px;
  border-radius: 12px;
`;

const NetworkIndicator = styled.div<{ warning: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 60px;
  margin-right: 10px;
  padding: 0 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 16px;
  background-color: #0a0e27;
  color: #ffffff;

  ${({ warning }) =>
    warning &&
    css`
      background: rgba(238, 60, 60, 0.15);
      backdrop-filter: blur(27px);
      color: #ee3c3c;
    `};

  ${Media.tablet} {
    justify-content: center;
    height: 50px;
    width: 50px;
    padding: 10px;
  }
`;

const ArbitrumLogo = styled.img<{ warning: boolean }>`
  width: 28px;
  height: 28px;
  margin-right: 10px;
  border-radius: ${({ warning }) => (warning ? "0px" : "14px")};
  object-fit: cover;
  overflow: hidden;

  ${Media.tablet} {
    margin-right: 0;
  }
`;
