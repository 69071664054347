import { formatUnits, parseEther } from "@ethersproject/units";
import { Zero } from "@ethersproject/constants"
import { BigNumber, Contract } from "ethers";
import { isNFTCollection, isShellToken, tokenMap } from "../placeholders/tokens";
import { Edge, getTokenID } from "./LiquidityGraph";
import * as ShellV2 from './ocean/index';
import { LiquidityPoolABI } from "../constants/ABI/LiquidityPoolABI";
import { OCEAN_ADDRESS, OCEAN_POOL_QUERY_ADDRESS } from "../constants/addresses";
import { OceanABI } from "../constants/ABI/OceanABI";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { addPrice } from "../store/pricesSlice";
import { OceanPoolQueryABI } from "../constants/ABI/OceanPoolQueryABI";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";
import { multicall } from "./nftHelpers";

export interface PoolState {
    xBalance: BigNumber
    yBalance: BigNumber
    totalSupply: BigNumber
    impAddress: string
}

export class PoolQuery {

    poolMap : {[name: string] : Contract} = {}
    ocean : Contract
    oceanPoolQuery : Contract

    prices = useAppSelector(state => state.prices.prices)
    dispatch = useAppDispatch()

    constructor(connectedWallet : any) {
        const shellTokens : any[] = Object.values(tokenMap).filter((token) => isShellToken(token))
        shellTokens.forEach((shellToken) => {
            this.poolMap[shellToken.name] = new Contract(
                shellToken.pool,
                LiquidityPoolABI,
                connectedWallet
            );
        })

        this.ocean = new Contract(
            OCEAN_ADDRESS,
            OceanABI,
            connectedWallet
        );

        this.oceanPoolQuery = new Contract(
            OCEAN_POOL_QUERY_ADDRESS,
            OceanPoolQueryABI, 
            connectedWallet
        )
    }

    findSharedPools = async (paths: Edge[][]) => {

        const pathPools = paths.map((path) => path.map((edge) => edge.pool ?? '').filter((pool) => pool !== '' && isShellToken(tokenMap[pool])))
        const sharedPoolState : { [id: string]: PoolState } = {}
    
        for(let i = 0; i < pathPools.length; i++){
    
            for(let j = 0; j < pathPools[i].length; j++){
                
                let pool = pathPools[i][j]
    
                for(let k = i + 1; k < pathPools.length; k++){
    
                    if(pathPools[k].includes(pool) && !(pool in sharedPoolState)){
                        // TODO: replace with different state loading function for non native pools
                        // @ts-ignore
                        const state = await this.oceanPoolQuery.getPoolState(tokenMap[pool].pool)
                        sharedPoolState[pool] = {
                            xBalance: state[0], 
                            yBalance: state[1], 
                            totalSupply: state[2], 
                            impAddress: state[3]
                        }
                    }
                }
            }
        }
    
        return sharedPoolState
    
    }

    sortPaths = (paths: Edge[][], amounts: BigNumber[]) => {

        // Create an array of indices for the paths array
        const indices = paths.map((_, index) => index);

        // Sort the indices array based on the length of subarrays in paths
        indices.sort((a, b) => paths[b].length - paths[a].length);

        // Create sorted arrays for paths and nftPaths based on the sorted indices
        const sortedPaths = indices.map(index => paths[index]);
        const sortedAmounts = indices.map(index => amounts[index]);

        return [sortedPaths, sortedAmounts]
    }

    filterInputNFTPath = (paths: Edge[][]) => {
        const inputNFTPaths: Edge[][] = []
        paths.forEach((path) => {
            const inputNFTPath : Edge[] = []

            while(path.length > 0 && isNFTCollection(path[0].token)){
                inputNFTPath.push(path.shift()!)
            }
            if(path.length) inputNFTPath.push(path[0])
            inputNFTPaths.push(inputNFTPath)
        })

        return [paths, inputNFTPaths]

    }

    filterOutputNFTPath = (paths: Edge[][]) => {
        const outputNFTPaths: Edge[][] = []
        paths.forEach((path) => {
            const outputNFTPath : Edge[] = []

            while(path.length > 0 && isNFTCollection(path[path.length - 1].token)){
                outputNFTPath.unshift(path.pop()!)
            }
            if(path.length) outputNFTPath.unshift(path[path.length - 1])
            outputNFTPaths.push(outputNFTPath)
        })
        return [paths, outputNFTPaths]

    }

    adjustNFTAmount = (amount : number, nftPath : Edge[]) => {
        const exchangeRate = 100
        nftPath.forEach((step : Edge) => {
            if(step.action == 'Fractionalize' || step.action == 'Unfractionalize') amount *= exchangeRate
        })
        return amount
    }

    query = async (path: Edge[], amount: BigNumber, sharedPoolState: { [id: string]: PoolState }, specifiedInput : boolean) => {

        // @ts-ignore
        const sharedPools = Object.keys(sharedPoolState).map((pool) => tokenMap[pool].pool)

        const steps = []
        let unfractionalizeRate = 0
        if(specifiedInput){
            for (let j = 0; j < path.length - 1; j++) {
                const action = path[j+1].action
                if(action == 'Wrap' || action == 'Unwrap') continue
                steps.push({
                    token: path[action == 'Withdraw' ? j + 1 : j].token.oceanID,
                    // @ts-ignore
                    pool: tokenMap[path[j+1].pool].pool,
                    action: action == 'Swap' ? 0 : action == 'Deposit' ? 2 : 4
                })
            }
        } else {
            for (let j = path.length - 1; j >= 1; j--) {
                const action = path[j].action
                if(action == 'Wrap' || action == 'Unwrap') continue
                steps.push({
                    token: path[action == 'Deposit' ? j - 1 : j].token.oceanID,
                    // @ts-ignore
                    pool: tokenMap[path[j].pool].pool,
                    action: action == 'Swap' ? 1 : action == 'Deposit' ? 3 : 5
                })
            }
        }

        const result = await this.oceanPoolQuery.query(steps, amount, sharedPools, Object.values(sharedPoolState))

        return {
            amount: result[0].div(unfractionalizeRate > 0 ? unfractionalizeRate : 1),
            poolStates: result[1]
        }
    }

    getUSDPrice = async (token : any, cachedPrices? : any) => {
        const prices = cachedPrices ?? this.prices

        const tokenID = getTokenID(token)
        if(prices[tokenID]){
            if(typeof prices[tokenID] == 'number'){
                return prices[tokenID]
            } else {
                return prices[tokenID].find((item: any) => item.id == token.id1155)?.price ?? 0
            }
        } else if(isShellToken(token)){
            const price = await this.getShellTokenPrice(token, prices)
            this.dispatch(addPrice({name: tokenID, price: price}))
            if(cachedPrices) cachedPrices[tokenID] = price
            return price
        } else {
            return 0
        }
    }

    getShellTokenPrice = async (shellToken : any, prices : any) => {
    
        const poolContract = this.poolMap[shellToken.name]      
        const childTokens = shellToken.tokens.map((child : any) => tokenMap[child])
        const priceBalances = []
    
        const childOceanIDs = childTokens.map((childToken : any) => 
            isShellToken(childToken) ? childToken.oceanID : 
            childToken.wrapped ? 
            childToken.oceanID : 
            ShellV2.utils.calculateWrappedTokenId(childToken.address, 0)
        )

        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'Ocean',
                contractAddress: this.ocean.address,
                abi: OceanABI as any,
                calls: [{
                    reference: "balanceOfBatch",
                    methodName: "balanceOfBatch",
                    methodParameters: [[poolContract.address, poolContract.address], childOceanIDs]
                }]
            },
            {
                reference: 'Pool',
                contractAddress: poolContract.address,
                abi: LiquidityPoolABI as any,
                calls: [{
                    reference: "getTokenSupply",
                    methodName: "getTokenSupply",
                    methodParameters: [shellToken.oceanID]
                }]
            },
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext)

        const balances = results.results['Ocean'].callsReturnContext[0].returnValues.map((value) => BigNumber.from(value))

        for(let i = 0; i < childTokens.length; i++){
            const childToken = childTokens[i]
            const childTokenID = getTokenID(childToken)
            const price = prices[childTokenID] ?? (isShellToken(childToken) ? await this.getShellTokenPrice(childToken, prices) : 0)
            priceBalances.push({
                token: childTokenID,
                price: parseEther(price.toString()),
                balance: balances[i]
            })
        }

        let totalValue = Zero
        priceBalances.forEach((data : any) => totalValue = totalValue.add(data.balance.mul(data.price)))
        const totalSupply = results.results['Pool'].callsReturnContext[0].returnValues[0]
        const price : BigNumber = totalValue.div(totalSupply)
        
        return parseFloat(formatUnits(price))
    }
    
}