import React from "react";
import styled, { css } from "styled-components";
import { BounceLoader } from "../Loaders";
import { StatusBullet } from "@/pages/LBP/StatusBullet";

interface ItemProps {
  dataTestId: string;
  icon: string;
  title?: string;
  subtitle: string;
  textRight: string;
  onClick: () => void;
  color?: string;
  isLoading: boolean;
  selected: boolean;
  nftType?: string;
  disabled: boolean;
  status?: string
}

export const TokenButton = ({
  dataTestId,
  icon,
  title,
  subtitle,
  textRight,
  onClick,
  color,
  isLoading,
  selected,
  nftType,
  disabled,
  status,
}: ItemProps) => {
  return (
    <Button data-testid={dataTestId} selected={selected} onClick={onClick} disabled={isLoading} canSelect={!disabled}>
      <Logo src={icon} nftType={nftType} alt="Logo" />
      <div>
        {title && <Title>{title} {status && status != 'Ended' ? <StatusBullet small={true} status={status} /> : null}</Title>}
        <SubTitle>{subtitle}</SubTitle>
      </div>
      {isLoading ? (
        <BounceLoader />
      ) : nftType == '1155' && textRight == 'CollectionView' ? 
        <div style={{marginLeft: 'auto'}}><img src="../assets/icons/chevron-left.svg"/></div> 
        : (
        <TextRight color={color}>{textRight}</TextRight>
      )}
    </Button>
  );
};

const Button = styled.button<{selected: boolean, canSelect: boolean}>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 24px 16px 20px;
  text-align: left;
  background: ${props => props.selected ? 'linear-gradient(#171B33, #171B33) padding-box, linear-gradient(to right, #37dcf2, #07c0fb) border-box' : '#171B33'};
  border: 1px solid transparent;
  border-radius: 16px;

  ${({ canSelect }) =>
    !canSelect &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    box-shadow: 0px 19px 45px #04081C;
    border-color: transparent;
    outline: ${props => props.selected ? 'none' : '2px solid #2C5173'};
  }
`;

const Logo = styled.img<{nftType?: string}>`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 20px;
  overflow: hidden;

  ${({ nftType }) => nftType == '721' && css`border-radius: 0`}
  ${({ nftType }) => nftType == '1155' && css`border-radius: 8px`}

`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
  display: flex;
    align-items: center;
    gap: 8px;
`;

const SubTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
`;

const TextRight = styled.p<{ color?: string }>`
  margin-left: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #ffffff;
  ${({ color }) =>
    color === "green" &&
    css`
      color: #7adeb9;
    `};
  ${({ color }) =>
    color === "red" &&
    css`
      color: #ff5349;
    `};
  ${({ color }) =>
    color === "blue" &&
    css`
      color: #37dcf2;
    `};
`;
