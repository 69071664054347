import { configureStore } from '@reduxjs/toolkit'
import balancesReducer from './balancesSlice'
import quotasReducer from './quotasSlice'
import poolsReducer from './poolsSlice'
import transactionsReducer from './transactionsSlice'
import pricesReducer from './pricesSlice'
import leaderboardsReducer from './leaderboardsSlice'
import metricsReducer from './metricsSlice'
import lbpsReducer from './lbpsSlice'
import pointsReducer from './pointsSlice'

export const store = configureStore({
  reducer: {
    balances: balancesReducer,
    quotas: quotasReducer,
    pools: poolsReducer,
    transactions: transactionsReducer,
    prices: pricesReducer,
    leaderboards: leaderboardsReducer,
    metrics: metricsReducer,
    lbps: lbpsReducer,
    points: pointsReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch