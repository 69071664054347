import { 
    DAI_ADDRESS, 
    USDC_ADDRESS, 
    USDT_ADDRESS, 
    ETH_ADDRESS, 
    WBTC_ADDRESS, 
    DAI_USDC_ADDRESS,
    USDT_USDC_ADDRESS, 
    STABLEPOOL_ADDRESS,
    ETH_USD_ADDRESS,
    WBTC_USD_ADDRESS,
    WSTETH_ADDRESS,
    WSTETH_ETH_ADDRESS,
    TOUCAN_ADDRESS,
    ARB_ADDRESS,
    ARB_ETH_ADDRESS,
    TOUCOIN_ID,
    TOUCOIN_ETH_ADDRESS,
    MAGIC_ADDRESS,
    MAGIC_ETH_ADDRESS,
    MAGIC_ETH_LP_TOKEN_ID,
    SMOL_ADDRESS,
    SMOLCOIN_ID,
    SMOLPOOL_ADDRESS,
    SMOLPOOL_LP_TOKEN_ID,
    COLLAB_ADDRESS,
    REUNI_ADDRESS,
    REUNI_ETH_ADDRESS,
    REUNI_ETH_LP_TOKEN_ID,
    STG_ADDRESS,
    STG_ETH_LP_TOKEN_ID,
    STG_ETH_ADDRESS,
    BOOTY_ADDRESS,
    COLLAB_ETH_ADDRESS,
    OLD_BOOTY_ADDRESS
} from "../constants/addresses";

import { 
    DAI_USDC_LP_TOKEN_ID, 
    USDT_USDC_LP_TOKEN_ID, 
    STABLEPOOL_LP_TOKEN_ID, 
    ETH_USD_LP_TOKEN_ID, 
    WBTC_USD_LP_TOKEN_ID, 
    WSTETH_ETH_LP_TOKEN_ID,
    ARB_ETH_LP_TOKEN_ID,
    TOUCOIN_ETH_LP_TOKEN_ID,
    COLLAB_ETH_LP_TOKEN_ID
} from "../constants/addresses";

import daiIcon from "../assets/coins/DAI.svg";
import usdcIcon from "../assets/coins/USDC.svg";
import usdtIcon from "../assets/coins/USDT.svg";
import wbtcIcon from "../assets/coins/WBTC.svg";
import ethIcon from "../assets/coins/ETH.svg";
import wstethIcon from "../assets/coins/wstETH.svg";
import arbIcon from "../assets/coins/ARB.svg";
import shDaiIcon from "../assets/coins/shDAI.svg";
import shUsdcIcon from "../assets/coins/shUSDC.svg";
import shUsdtIcon from "../assets/coins/shUSDT.svg";
import shWbtcIcon from "../assets/coins/shWBTC.svg";
import shEthIcon from "../assets/coins/shETH.svg";
import shwstEthIcon from "../assets/coins/shwstETH.svg";
import shArbIcon from "../assets/coins/shARB.svg";
import daiUsdcIcon from "../assets/coins/dai-usdc.svg";
import usdtUsdcIcon from "../assets/coins/usdt-usdc.svg";
import stablepoolIcon from "../assets/coins/stablepool.svg";
import ethUsdIcon from "../assets/coins/eth-usd.svg";
import wbtcUsdIcon from "../assets/coins/wbtc-usd.svg";
import wstethEthIcon from "../assets/coins/wsteth-eth.svg";
import arbEthIcon from "../assets/coins/arb-eth.svg";
import toucanIcon from "../assets/coins/TOUCAN.svg";
import shToucanIcon from "../assets/coins/shTOUCAN.svg";
import toucoinIcon from "../assets/coins/TOUCOIN.svg";
import toucoinEthIcon from "../assets/coins/toucoin-eth.svg"
import magicIcon from "../assets/coins/MAGIC.svg"
import shMagicIcon from "../assets/coins/shMAGIC.svg"
import magicEthIcon from "../assets/coins/magic-eth.svg"
import smolIcon from "../assets/coins/SMOL.png"
import shSmolIcon from "../assets/coins/shSMOL.png"
import smolcoinIcon from "../assets/coins/SMOLCOIN.png"
import smolpoolIcon from "../assets/coins/smolpool.png"
import collabIcon from "../assets/coins/COLLAB.svg"
import shCollabIcon from "../assets/coins/shCOLLAB.svg"
import reuniIcon from "../assets/coins/REUNI.png"
import shReuniIcon from "../assets/coins/shREUNI.png"
import reuniEthIcon from "../assets/coins/reuni-eth.svg"
import stgIcon from "../assets/coins/STG.png"
import shStgIcon from "../assets/coins/shSTG.png"
import stgEthIcon from "../assets/coins/stg-eth.svg"
import bootyIcon from "../assets/seasons/season1.png"
import shBootyIcon from "../assets/coins/shBKB.png"
import smashedExpanderEthIcon from "../assets/coins/smashed-expander-eth.svg"
import yachtChunkEthIcon from "../assets/coins/yacht-chunk-eth.svg"
import sunkenDroneEthIcon from "../assets/coins/sunken-drone-eth.svg"
import collabEthIcon from "../assets/coins/collab-eth.svg"
import countdownCrateEthIcon from "../assets/coins/countdown-crate-eth.svg"

import accountantIcon from '../assets/booty/accountant-head.png'
import shAccountantIcon from '../assets/coins/shACCT.svg'
import { calculateWrappedTokenId } from "../utils/ocean/utils";
import placeholderIcon from "../assets/coins/logo-placeholder.svg"
import { itemsMap } from "@/pages/Booty/Items";

// @dev hexadecimal(ascii("Ether"))
export interface Token {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  oceanID?: string;
  icon: string;
  status?: string;
}

export const tokens: Token[] = [
  {
    name: "Ether",
    symbol: "ETH",
    address: ETH_ADDRESS,
    wrapped: false,
    icon: ethIcon
  },
  {
    name: "Dai",
    symbol: "DAI",
    address: DAI_ADDRESS,
    wrapped: false,
    icon: daiIcon
  },
  {
    name: "Bridged USDC",
    symbol: "USDC.e",
    address: USDC_ADDRESS,
    wrapped: false,
    icon: usdcIcon
  },
  {
    name: "Tether",
    symbol: "USDT",
    address: USDT_ADDRESS,
    wrapped: false,
    icon: usdtIcon
  },
  {
    name: "Wrapped Bitcoin",
    symbol: "WBTC",
    address: WBTC_ADDRESS,
    wrapped: false,
    icon: wbtcIcon
  },
  {
    name: 'Staked Ether',
    symbol: 'wstETH',
    address: WSTETH_ADDRESS,
    wrapped: false,
    icon: wstethIcon
  },
  {
    name: 'Arbitrum',
    symbol: 'ARB',
    address: ARB_ADDRESS,
    wrapped: false,
    icon: arbIcon
  },
  {
    name: 'Magic',
    symbol: 'MAGIC',
    address: MAGIC_ADDRESS,
    wrapped: false,
    icon: magicIcon
  },
  {
    name: 'Reunit Wallet',
    symbol: 'REUNI',
    address: REUNI_ADDRESS,
    wrapped: false,
    icon: reuniIcon
  },
  {
    name: 'Stargate',
    symbol: 'STG',
    address: STG_ADDRESS,
    wrapped: false,
    icon: stgIcon
  },
  {
    name: 'Wrapped Ether',
    symbol: 'shETH',
    address: ETH_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(ETH_ADDRESS, 0),
    icon: shEthIcon
  },
  {
    name: "Wrapped Dai",
    symbol: "shDAI",
    address: DAI_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(DAI_ADDRESS, 0),
    icon: shDaiIcon
  },
  {
    name: "Wrapped USD Coin",
    symbol: "shUSDC.e",
    address: USDC_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(USDC_ADDRESS, 0),
    icon: shUsdcIcon
  },
  {
    name: "Wrapped Tether",
    symbol: "shUSDT",
    address: USDT_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(USDT_ADDRESS, 0),
    icon: shUsdtIcon
  },
  {
    name: "Wrapped WBTC",
    symbol: "shWBTC",
    address: WBTC_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(WBTC_ADDRESS, 0),
    icon: shWbtcIcon,
  },
  {
    name: 'Wrapped wstETH',
    symbol: 'shwstETH',
    address: WSTETH_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(WSTETH_ADDRESS, 0),
    icon: shwstEthIcon
  },
  {
    name: 'Wrapped Arbitrum',
    symbol: 'shARB',
    address: ARB_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(ARB_ADDRESS, 0),
    icon: shArbIcon
  },
  {
    name: 'Wrapped Magic',
    symbol: 'shMAGIC',
    address: MAGIC_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(MAGIC_ADDRESS, 0),
    icon: shMagicIcon
  },
  {
    name: 'Wrapped CollabLand',
    symbol: 'shCOLLAB',
    address: COLLAB_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(COLLAB_ADDRESS, 0),
    icon: shCollabIcon
  },
  {
    name: 'Wrapped Reunit',
    symbol: 'shREUNI',
    address: REUNI_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(REUNI_ADDRESS, 0),
    icon: shReuniIcon
  },
  {
    name: 'Wrapped Stargate',
    symbol: 'shSTG',
    address: STG_ADDRESS,
    wrapped: true,
    oceanID: calculateWrappedTokenId(STG_ADDRESS, 0),
    icon: shStgIcon
  },
  {
    name: 'Fractional Toucans',
    symbol: 'TOUCOIN',
    address: TOUCAN_ADDRESS,
    wrapped: true,
    oceanID: TOUCOIN_ID,
    icon: toucoinIcon
  },
  {
    name: 'Fractional Smol Brains',
    symbol: 'SMOLCOIN',
    address: SMOL_ADDRESS,
    wrapped: true,
    oceanID: SMOLCOIN_ID,
    icon: smolcoinIcon
  }
]

export interface ShellToken extends Token {
  oceanID: string;
  pool: string;
  tokens: string[];
  fee: number;
}

export const shellTokens: ShellToken[] = [
  {
    name: "DAI+USDC",
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: daiUsdcIcon,
    oceanID: DAI_USDC_LP_TOKEN_ID,
    pool: DAI_USDC_ADDRESS,
    tokens: ["DAI", "USDC.e"],
    fee: 0.025,
  },
  {
    name: "USDT+USDC",
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: usdtUsdcIcon,
    oceanID: USDT_USDC_LP_TOKEN_ID,
    pool: USDT_USDC_ADDRESS,
    tokens: ["USDT", "USDC.e"],
    fee: 0.025,
  },
  {
    name: "Stablepool",
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: stablepoolIcon,
    oceanID: STABLEPOOL_LP_TOKEN_ID,
    pool: STABLEPOOL_ADDRESS,
    tokens: ["USDT+USDC", "DAI+USDC"],
    fee: 0.025,
  },
  {
    name: "ETH+USD",
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: ethUsdIcon,
    oceanID: ETH_USD_LP_TOKEN_ID,
    pool: ETH_USD_ADDRESS,
    tokens: ["ETH", "Stablepool"],
    fee: 0.25,
  },
  {
    name: "WBTC+USD",
    symbol: "shLP",
    address: "0",
    wrapped: true,
    icon: wbtcUsdIcon,
    oceanID: WBTC_USD_LP_TOKEN_ID,
    pool: WBTC_USD_ADDRESS,
    tokens: ['WBTC', 'Stablepool'],
    fee: 0.25
  },
  {
    name: 'wstETH+ETH',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: wstethEthIcon,
    oceanID: WSTETH_ETH_LP_TOKEN_ID,
    pool: WSTETH_ETH_ADDRESS,
    tokens: ['wstETH', 'ETH'],
    fee: 0.025
  },
  {
    name: 'ARB+ETH',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: arbEthIcon,
    oceanID: ARB_ETH_LP_TOKEN_ID,
    pool: ARB_ETH_ADDRESS,
    tokens: ['ARB', 'ETH'],
    fee: 0.25
  },
  {
    name: 'TOUCOIN+ETH',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: toucoinEthIcon,
    oceanID: TOUCOIN_ETH_LP_TOKEN_ID,
    pool: TOUCOIN_ETH_ADDRESS,
    tokens: ['TOUCOIN', 'ETH'],
    fee: 0.25
  },
  {
    name: 'MAGIC+ETH',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: magicEthIcon,
    oceanID: MAGIC_ETH_LP_TOKEN_ID,
    pool: MAGIC_ETH_ADDRESS,
    tokens: ['MAGIC', 'ETH'],
    fee: 0.25
  },
  {
    name: 'Smolpool',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: smolpoolIcon,
    oceanID: SMOLPOOL_LP_TOKEN_ID,
    pool: SMOLPOOL_ADDRESS,
    tokens: ['SMOLCOIN', 'MAGIC+ETH'],
    fee: 0.25
  },
  {
    name: 'REUNI+ETH',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: reuniEthIcon,
    oceanID: REUNI_ETH_LP_TOKEN_ID,
    pool: REUNI_ETH_ADDRESS,
    tokens: ['REUNI', 'ETH'],
    fee: 0.25
  },
  {
    name: 'STG+ETH',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: stgEthIcon,
    oceanID: STG_ETH_LP_TOKEN_ID,
    pool: STG_ETH_ADDRESS,
    tokens: ['STG', 'ETH'],
    fee: 0.25
  },
  {
    name: 'Smashed Expander Pool',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: smashedExpanderEthIcon,
    oceanID: '0x980566071eb39023d14599daf821c3dbaf78da1adec55bae051733cb86487f20',
    pool: '0x375C99d48CA8Bfd8054d4b0FFDa5807bb7FeA11d',
    tokens: ['frBKB-7', 'ETH'],
    fee: 0.25
  },
  {
    name: 'Yacht Chunk Pool',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: yachtChunkEthIcon,
    oceanID: '0x083b70127ec5b214013ab40fadce9d18fad5521fb05ef26ae236c06bc6f19ddc',
    pool: '0x4a003F8131632b6b3d1C2d9305b742EB94EC89F0',
    tokens: ['frBKB-12', 'ETH'],
    fee: 0.25
  },
  {
    name: 'Sunken Drone Pool',
    symbol: 'shLP',
    address: '0',
    wrapped: true,
    icon: sunkenDroneEthIcon,
    oceanID: '0x1068fc2b86e04f317a151bd57f9e43af28562996d5abe3229dcf297ac0dc8069',
    pool: '0x72EB5CA9E4A0aeDf9215403E810790E19A9483B2',
    tokens: ['frBKB-13', 'ETH'],
    fee: 0.25
  }
];

export interface NFTCollection extends Token {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  ipfsHash: string;
  fractionalizer?: string;
  maxSupply?: number;
  is1155?: boolean
  id1155?: number;
}

export const nftCollections: NFTCollection[] = [
  {
    name: "Government Toucans",
    symbol: "TOUCAN",
    address: TOUCAN_ADDRESS,
    wrapped: false,
    icon: toucanIcon,
    ipfsHash: 'QmNzwGJJEFNsck7havfqX7MjVRtiWpuAA7MWwc9kD8XHys',
    fractionalizer: '0x2946012D62f7b9B5E6AEBfE364b91CBe5f32B6A4'
  },
  {
    name: "Wrapped Toucans",
    symbol: "shTOUCAN",
    address: TOUCAN_ADDRESS,
    wrapped: true,
    icon: shToucanIcon,
    ipfsHash: 'QmNzwGJJEFNsck7havfqX7MjVRtiWpuAA7MWwc9kD8XHys',
    fractionalizer: '0x2946012D62f7b9B5E6AEBfE364b91CBe5f32B6A4',
    maxSupply: 47021
  },
  {
    name: "Smol Brains",
    symbol: "SMOL",
    address: SMOL_ADDRESS,
    wrapped: false,
    icon: smolIcon,
    ipfsHash: '',
    fractionalizer: '0x1C6b111D7Bf7d2D602ba2eCA1938b47359f73957'
  },
  {
    name: "Wrapped Smol Brains",
    symbol: "shSMOL",
    address: SMOL_ADDRESS,
    wrapped: true,
    icon: shSmolIcon,
    ipfsHash: '',
    fractionalizer: '0x1C6b111D7Bf7d2D602ba2eCA1938b47359f73957',
    maxSupply: 13422
  },
  {
    name: "Bro Kwon's Booty",
    symbol: "BKB",
    address: OLD_BOOTY_ADDRESS,
    wrapped: false,
    icon: bootyIcon,
    ipfsHash: '',
    fractionalizer: '0x6cefEbACDd6D8eE4ddfD17F0f6A3d1753A4B9f78',
    is1155: true,
    maxSupply: 21
  },
  {
    name: "Wrapped Booty",
    symbol: "shBKB",
    address: OLD_BOOTY_ADDRESS,
    wrapped: true,
    icon: shBootyIcon,
    ipfsHash: '',
    fractionalizer: '0x6cefEbACDd6D8eE4ddfD17F0f6A3d1753A4B9f78',
    is1155: true,
    maxSupply: 21
  },
  {
    name: "Accountant's Trove",
    symbol: "ACCT",
    address: BOOTY_ADDRESS,
    wrapped: false,
    icon: accountantIcon,
    ipfsHash: '',
    fractionalizer: '0xB4815284F7d5C0e7235f170f8b55431024133Bec',
    is1155: true,
    maxSupply: 27
  },
  {
    name: "Wrapped Trove",
    symbol: "shACCT",
    address: BOOTY_ADDRESS,
    wrapped: true,
    icon: shAccountantIcon,
    ipfsHash: '',
    fractionalizer: '0xB4815284F7d5C0e7235f170f8b55431024133Bec',
    is1155: true,
    maxSupply: 27
  }
];

export interface NFT {
  id: number;
  symbol: string;
  name: string;
  wrapped: boolean;
  rarity: number;
  image: string;
}

export interface LBPToken extends Token {
  description: string;
  maxSupply?: number;
  pairToken: string;
  poolName: string;
  poolAddress: string;
  poolOceanID: string;
  poolIcon: string;
  fee: number;
}

export const lbpTokens: LBPToken[] = [
//   {
//     name: "Shell",
//     symbol: "SHELL",
//     description: 'SHELL Liquidity Bootstraping Pool',
//     address: '0',
//     wrapped: false,
//     icon: lbpIcon,
//     pairToken: 'USDC.e'
//   },
  {
    name: 'CollabLand',
    symbol: 'COLLAB',
    description: 'Collab',
    address: COLLAB_ADDRESS,
    wrapped: false,
    icon: collabIcon,
    pairToken: 'ETH',
    poolName: 'COLLAB+ETH',
    poolAddress: COLLAB_ETH_ADDRESS,
    poolOceanID: COLLAB_ETH_LP_TOKEN_ID,
    poolIcon: collabEthIcon,
    fee: 0.25
  },
  {
    name: 'Countdown Crate',
    symbol: 'frACCT-21',
    description: 'Countdown Crate',
    address: BOOTY_ADDRESS,
    wrapped: true,
    icon: '',
    pairToken: 'ETH',
    poolName: 'Countdown Crate Pool',
    poolAddress: '0x3c118c900dd4DCBF4E5f1e8dE619A394328507B6',
    poolOceanID: '0x7bed3e8c46f7b84cf62e5939d69d0c7267d8e4a5ca6777c76358b93685deb4a4',
    poolIcon: countdownCrateEthIcon,
    fee: 0.25
  },
];

export interface NFT1155 {
    id: number;
    name: string;
    image: string;
    balance: number;
    desc: string;
}

export function isShellToken(token: any): token is ShellToken {
  return (
    token &&
    "name" in token &&
    "icon" in token &&
    "oceanID" in token &&
    "pool" in token &&
    "tokens" in token
  );
}

export function isNFTCollection(token : any): token is NFTCollection {
    return (
        token &&
        "name" in token &&
        "symbol" in token &&
        "address" in token &&
        "wrapped" in token &&
        "ipfsHash" in token
    );
}

export function isLBPToken(token : any): token is LBPToken {
  return (
      token &&
    //   token.name.includes('lbp') &&
      "name" in token &&
      "description" in token &&
      "symbol" in token &&
      "address" in token &&
      "wrapped" in token &&
      "pairToken" in token
  );
}

export function isDefaultShellToken(token: any) {
  return token.symbol == "shLP" && !isShellToken(token);
}

export function maxTokenDecimals(token : string) {
    if(token.includes('WBTC') && !token.includes('+'))
        return 5
    else if(token.includes('ETH') && !token.includes('+'))
        return 3
    else
        return 2
}

export const tokenMap: { [id: string]: Token | ShellToken | NFTCollection | LBPToken } = {};

tokens.forEach((token) => {
  if (token.symbol !== "shLP") {
    tokenMap[token.symbol] = token;
  }
});

shellTokens.forEach((token) => {
  tokenMap[token.name] = token;
});

nftCollections.forEach((collection) => tokenMap[collection.symbol] = collection)

lbpTokens.forEach((lbpToken) => {
    tokenMap[lbpToken.symbol] = lbpToken
    tokenMap[lbpToken.poolName] = {
        name: lbpToken.poolName,
        symbol: 'shLP',
        address: '0',
        wrapped: true,
        icon: lbpToken.poolIcon,
        oceanID: lbpToken.poolOceanID,
        pool: lbpToken.poolAddress,
        tokens: [lbpToken.symbol, lbpToken.pairToken],
        fee: lbpToken.fee
    }
})
