export const OCEAN_ADDRESS = "0xC32eB36f886F638fffD836DF44C124074cFe3584";
export const OCEAN_OWNER_ADDRESS = "0x07c648648A6Bd4Ab688a1995FC049285F541FE20";
export const OCEAN_POOL_QUERY_ADDRESS = "0xffA441744D9BF3F63fdfcD7B41d042bBbf443DAb";

export const DAI_ADDRESS = "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1";
export const USDC_ADDRESS = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
export const USDT_ADDRESS = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";
export const WBTC_ADDRESS = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
export const WSTETH_ADDRESS = "0x5979D7b546E38E414F7E9822514be443A4800529";
export const ARB_ADDRESS = "0x912CE59144191C1204E64559FE8253a0e49E6548";
export const MAGIC_ADDRESS = "0x539bdE0d7Dbd336b79148AA742883198BBF60342";
export const COLLAB_ADDRESS = "0xf18C263EC50CC211EF3F172228549B6618F10613";
export const REUNI_ADDRESS = "0x9ed7E4B1BFF939ad473dA5E7a218C771D1569456";
export const STG_ADDRESS = "0x6694340fc020c5E6B96567843da2df01b2CE1eb6";

export const DAI_USDC_ADDRESS = "0x96C7dC9d473e621a1e3968Cb862803EAEDe21888";
export const DAI_USDC_LP_TOKEN_ID = "0xbebb2eb60c5f0f6b2851070df30eb6b21f828c3b8242fd438caed9ca074a4436";
export const USDT_USDC_ADDRESS = "0x0cb736ea2AD425221c368407CAAFDD323b7bDc83";
export const USDT_USDC_LP_TOKEN_ID = "0xaed7b01708bac910285a0331e9d703cc070c2c3ab641b4aec46feffa10f0d46b";
export const STABLEPOOL_ADDRESS = "0x4f9d367636d5d2056f848803C11872Fdbc2afc47";
export const STABLEPOOL_LP_TOKEN_ID = "0x6fa5e1f835f60b6883a60a12ec43e1534c10acef7b090b1b6a36b82876f54de9";
export const ETH_USD_ADDRESS = "0xa2dB39e781A5Eee0EAA625dace6F097c17dfF7Ea";
export const ETH_USD_LP_TOKEN_ID = "0x3d39f7c71b4be4b2b39924b0aefbe172194c1a8b11ff4e31a74896edead80b02";
export const WBTC_USD_ADDRESS = "0x3402D87DF0817b2A96b210b8873d33dD979C8D19";
export const WBTC_USD_LP_TOKEN_ID = "0x0e53d22e36f0a0403bacf365d574a97253953118fb462cb766f6a1a34791d493";
export const WSTETH_ETH_ADDRESS = "0x2EaB95A938d1fAbb1b62132bDB0C5A2405a57887"
export const WSTETH_ETH_LP_TOKEN_ID = "0x39d7132d7d4337aea5f75f468e05f6efd8182efd66eb6ddc49a8c900548411db";
export const ARB_ETH_ADDRESS = "0xA16F40437213020A167c230E4667ff8f13640f75";
export const ARB_ETH_LP_TOKEN_ID = "0x977a1e4c16998d4b94d59494909c9503f5bc50642326bc8fa61f2e22761e7a3a";
export const MAGIC_ETH_ADDRESS = "0x0699645f2fd448398272aE07F82EEe8d0388DE1C";
export const MAGIC_ETH_LP_TOKEN_ID = "0xf00fbe5928cf77453911087439475eba4c4ddedd3f3664589eaa91c00e803377";
export const REUNI_ETH_ADDRESS = "0x3917c74FDeC42071E29461c849bceB81cBC3059c";
export const REUNI_ETH_LP_TOKEN_ID = "0x107a8aad95f88121f288baa229d82a6979de4158e6e0762a53e9ab24b4881c25";
export const STG_ETH_ADDRESS = "0xe043EB17Cc12C3fD4f5EaC765D0f1b965975F470";
export const STG_ETH_LP_TOKEN_ID = "0x395b21a145e98bc33138d4bc87a25e7ca0839b05bd16c1ed06476c5ceb5c0bfe";
export const COLLAB_ETH_ADDRESS = "0x6D0F58fdD73a34cb012B0bA10695440CBF3f7476";
export const COLLAB_ETH_LP_TOKEN_ID = "0x1a92dda5f59172bcf9e4338910a498864eaa25e835689ae7a9fe42d559a6a637";

export const TOUCAN_ADDRESS = '0x642FfAb2752Df3BCE97083709F36080fb1482c80'
export const TOUCOIN_ID = '0x22f353625821fc1deecf5e926787edee7672e0f4c537ba94b3f2b2b64b43d9be'
export const TOUCOIN_ETH_ADDRESS = "0x81F6F6664E8Ece1E81bc9097084373c1dDDb8Daa";
export const TOUCOIN_ETH_LP_TOKEN_ID = "0x9b499052e6ba1808bdaf05e612d98955f2aeea34bffd35318ba56c92dd8ead74";

export const SMOL_ADDRESS = '0xA7f1462e0EcdeEbDeE4FaF6681148Ca96Db78777'
export const SMOLCOIN_ID = '0x44e43f758fe59a8f0d5e19c28d27f04cdf380bb2c5c0d6394f2f242694be0a0f';
export const SMOLPOOL_ADDRESS = '0x6896177Ee52659f22A87b180e8FCB2c850A7427e'
export const SMOLPOOL_LP_TOKEN_ID = '0x6aa326cb31e0abc00882250a8489836657da968ec4226710f9e146139eded137'

export const WRAPPED_ETHER_ID = "0x97a93559a75ccc959fc63520f07017eed6d512f74e4214a7680ec0eefb5db5b4";
export const ETH_ADDRESS = "0x0000000000000000000000000000004574686572";

export const OLD_BOOTY_ADDRESS = "0x619F1f68a9a3cF939327801012E12f95B0312bB9"
export const BOOTY_ADDRESS = "0x4FcFf952894e0fD287322e58784ED082fDB6f931"

export const CRAB_ADDRESS = '0xcf17664006851A27d5Bd93d497f30853AC558792';
