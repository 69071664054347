import { BigNumber } from 'ethers/lib/ethers';
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import { ArrowIcon } from '../../components/Icons/ArrowIcon';
import { ConfirmIcon } from '../../components/Icons/ConfirmIcon';
import { Modal } from '../../components/Modal/Modal';
import { isNFTCollection, maxTokenDecimals, NFT, ShellToken, Token, tokenMap } from '../../placeholders/tokens';
import { formatDisplay, formatDisplayShorthand } from '../../utils/formatDisplay';
import { Edge, getTokenID, LiquidityGraph } from '../../utils/LiquidityGraph';
import { Accountant } from '../../components/Accountant'
import { parseEther, formatUnits } from 'ethers/lib/utils';
import { PoolQuery, PoolState } from '../../utils/PoolQuery';
import { useSigner, useProvider, useContract, useAccount } from 'wagmi';
import { Zero } from "@ethersproject/constants"
import { buildInteractions } from '../../utils/buildInteractions';
import * as ShellV2 from '../../utils/ocean/index';
import toast from 'react-hot-toast';
import { OceanABI } from '../../constants/ABI/OceanABI';
import { OCEAN_ADDRESS } from '../../constants/addresses';
import { tokenColors } from '../../constants/tokenColors';
import { wrappedTokens } from '../../constants/wrappedToken';
import { Spinner, StraightLoader } from '../../components/Loaders';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ErrorIcon } from '../../components/Icons/ErrorIcon';
import { Media } from '../../styles';
import { CONNECTED_CHAIN_EXPLORER } from '../../constants/chains';
import { NFTsSwiper } from './NFTsSwiper/NFTsSwiper';
import { calculateWrappedTokenId } from '../../utils/ocean/utils';
import { NFTWarning } from './NFTWarning';
import * as types from "../../utils/ocean/types";
import { buildNFTDisplays } from '../../utils/nftHelpers';
import { liquidityGraph } from '../../utils/testRouter';
import { clearUserTransactions } from "@/store/transactionsSlice";
import { clearCurrentPoints } from '@/store/pointsSlice';

interface ConfirmationModalProps {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setTradeDisabled: (disabled: boolean) => void,
    tokensFrom: any[],
    tokensTo: any[],
    specifiedAmounts: Record<string, string>,
    splitAmounts: Record<string, BigNumber>,
    slippage: number,
    fromInputs: boolean
    selectedNFTs: any
    setSelectedNFTs: any
    setTxSuccess: (val: boolean) => void
}

export const ConfirmationModal = ({visible, setVisible, setTradeDisabled, tokensFrom, tokensTo, specifiedAmounts, splitAmounts, slippage, fromInputs, selectedNFTs, setSelectedNFTs, setTxSuccess}: ConfirmationModalProps) => {
  
    const { data: signer } = useSigner();
    const provider = useProvider();
    const { address: walletAddress } = useAccount();
    // const liquidityGraph = new LiquidityGraph();
    const poolQuery = new PoolQuery(signer || provider);

    const inputNFTCollections = tokensFrom.filter((token) => isNFTCollection(token))?.map((token) => token.symbol) ?? []
    const outputNFTCollections = tokensTo.filter((token) => isNFTCollection(token))?.map((token) => token.symbol) ?? []
    const allNFTs = (fromInputs ? inputNFTCollections : outputNFTCollections).map((collection) => selectedNFTs[collection]).flat()

    const [amountsFrom, setAmountsFrom] = useState<Record<string, string>>({});
    const [amountsTo, setAmountsTo] = useState<Record<string, string>>({});

    const [maxAmountsFrom, setMaxAmountsFrom] = useState<{ [start: string]: { [end: string]: BigNumber } }>({});
    const [minAmountsTo, setMinAmountsTo] = useState<{ [end: string]: { [start: string]: BigNumber } }>({});

    const [foundNonWrap, setFoundNonWrap] = useState(false); // Flag to show slippage UI elements involved in swaps/deposits/withdraws
    const [summaryAmounts, setSummaryAmounts] = useState<{ [token: string]: string }>({});
    const [txState, setTxState] = useState('')
    const [txHash, setTxHash] = useState('')
    const [confirmDisabled, setConfirmDisabled] = useState(true);

    const [inputLoading, setInputLoading] = useState(false);
    const [outputLoading, setOutputLoading] = useState(false);

    const [unavailableNFTs, setUnavailableNFTs] = useState<{[collection : string] : NFT[]}>({})
    const [quotaTokens, setQuotaTokens] = useState<any[]>([])

    const userBalances = useAppSelector(state => state.balances.balances)
    const userQuotas = useAppSelector(state => state.quotas.quotas)
    const dispatch = useAppDispatch();

    const ocean = useContract({
        address: OCEAN_ADDRESS,
        abi: OceanABI,
        signerOrProvider: signer || provider
    });

    const wrapOrUnwrap = (tokenOne: Token | ShellToken, tokenTwo: Token | ShellToken) => {
        return (
            tokenOne.address == tokenTwo.address && 
            (
                tokenOne.wrapped !== tokenTwo.wrapped || 
                isNFTCollection(tokenOne) || 
                isNFTCollection(tokenTwo)
            )
        )
    }

    const updateOutputValues = async (inputTokens: any[], outputTokens: any[], split: boolean) => {

        setAmountsFrom(specifiedAmounts)

        const newAmountsTo: Record<string, string> = { ...amountsTo };
        const newMinAmountsTo: { [end: string]: { [start: string]: BigNumber } } = { ...minAmountsTo }
        const newSummaryAmounts: { [token: string]: string } = {};


        let paths: Edge[][] = [];
        let inputNFTPaths: Edge[][] = []
        const outputAmounts: { [end: string]: BigNumber } = {}

        if (split) {
            [paths, inputNFTPaths] = poolQuery.filterInputNFTPath(outputTokens.map((outputToken) => {
                outputAmounts[getTokenID(outputToken)] = Zero
                newMinAmountsTo[getTokenID(outputToken)] = {}
                return liquidityGraph.findPath(inputTokens[0], outputToken)
            }).sort((a, b) => b.length - a.length));
        } else {
            [paths, inputNFTPaths] = poolQuery.filterInputNFTPath(inputTokens.map((inputToken) =>
                liquidityGraph.findPath(inputToken, outputTokens[0])
            ).sort((a, b) => b.length - a.length));
            outputAmounts[getTokenID(outputTokens[0])] = Zero
            newMinAmountsTo[getTokenID(outputTokens[0])] = {}
        }

        const inputAmounts = paths.map((path, index) => {
            const tokenID = getTokenID((inputNFTPaths[index].length > 0 ? inputNFTPaths[index][0] : path[0]).token)
            const inputAmount = specifiedAmounts[tokenID] || '0'
            newSummaryAmounts[tokenID] = inputAmount
            return split ? 
                splitAmounts[getTokenID(path[path.length - 1].token)] : 
                parseEther(poolQuery.adjustNFTAmount(parseFloat(inputAmount), inputNFTPaths[index]).toFixed(18))
        })
        
        const sharedPoolState: { [id: string]: PoolState } = await poolQuery.findSharedPools(paths)
        const slippageMultiplier = 10000 - (slippage * 100)

        for (let i = 0; i < paths.length; i++) {

            const path = paths[i]

            if(path.length == 0 && inputNFTPaths[i].length > 0){ // Handle one step NFT wrap
                const [startToken, endToken] = [getTokenID(inputNFTPaths[i][0].token), getTokenID(inputNFTPaths[i][1].token)]
                outputAmounts[endToken] = inputAmounts[i];
                newMinAmountsTo[endToken][startToken] = inputAmounts[i]
                continue
            }

            const [startToken, endToken] = [getTokenID((inputNFTPaths[i].length > 0 ? inputNFTPaths[i][0] : path[0]).token), getTokenID(path[path.length - 1].token)]
            
            let resultAmount = Zero

            try {
              if(path.length == 1){
                resultAmount = inputAmounts[i]
              } else {
                const result = await poolQuery.query(path, inputAmounts[i], sharedPoolState, true)
                resultAmount = result.amount
                const newPoolStates = result.poolStates
                Object.keys(sharedPoolState).forEach((pool, index) => {
                  sharedPoolState[pool] = {
                    xBalance: newPoolStates[index][0], 
                    yBalance: newPoolStates[index][1], 
                    totalSupply: newPoolStates[index][2], 
                    impAddress: newPoolStates[index][3]
                  }
                })
              }
            } catch (_) {
              console.error('Proteus Query Error')
            }

            outputAmounts[endToken] = outputAmounts[endToken].add(resultAmount);
            if (wrapOrUnwrap(path[0].token, path[path.length - 1].token)) {
                newMinAmountsTo[endToken][startToken] = resultAmount
            } else {
                newMinAmountsTo[endToken][startToken] = resultAmount.mul(slippageMultiplier).div(10000)
                if (!foundNonWrap) setFoundNonWrap(true)
            }
        }

        Object.keys(outputAmounts).forEach((outputToken) => {
            const outputAmount = outputAmounts[outputToken]
            if (outputAmount.isZero()) {
                delete newAmountsTo[outputToken]
            } else {
                newAmountsTo[outputToken] = formatUnits(outputAmount)
            }
            let summaryAmount = Zero
            Object.keys(newMinAmountsTo[outputToken]).forEach((inputToken) =>
                summaryAmount = summaryAmount.add(newMinAmountsTo[outputToken][inputToken])
            )
            newSummaryAmounts[outputToken] = formatUnits(summaryAmount)
        })

        setAmountsTo(newAmountsTo);
        setMinAmountsTo(newMinAmountsTo);
        setSummaryAmounts(newSummaryAmounts);

    }

    const updateInputValues = async (outputTokens: any[], inputTokens: any[], split: boolean) => {

        setAmountsTo(specifiedAmounts)

        const newAmountsFrom: Record<string, string> = { ...amountsFrom };
        const newMaxAmountsFrom : {[start: string] : {[end: string] : BigNumber}} = {...maxAmountsFrom}
        const newSummaryAmounts : {[token: string] : string} = {};

        let paths: Edge[][] = [];
        let outputNFTPaths: Edge[][] = []
        const inputAmounts: { [start: string]: BigNumber } = {}

        if (split) {
            [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(inputTokens.map((inputToken) => {
                inputAmounts[getTokenID(inputToken)] = Zero
                newMaxAmountsFrom[getTokenID(inputToken)] = {}
                return liquidityGraph.findPath(inputToken, outputTokens[0])
            }).sort((a, b) => {  
                if (a.map((step) => step.token).includes(tokenMap['ETH'])) {
                    return -1;
                } else if (b.map((step) => step.token).includes(tokenMap['ETH'])) {
                    return 1; 
                }
                return b.length - a.length;
            }));
        } else {
            [paths, outputNFTPaths] = poolQuery.filterOutputNFTPath(outputTokens.map((outputToken) =>
                liquidityGraph.findPath(inputTokens[0], outputToken)
            ).sort((a, b) => b.length - a.length));
            inputAmounts[getTokenID(inputTokens[0])] = Zero
            newMaxAmountsFrom[getTokenID(inputTokens[0])] = {}
        }

        const outputAmounts = paths.map((path, index) => {
            const tokenID = getTokenID((outputNFTPaths[index].length > 0 ? outputNFTPaths[index].slice(-1)[0] : path[path.length - 1]).token)
            const outputAmount = specifiedAmounts[tokenID] || '0'
            newSummaryAmounts[tokenID] = outputAmount
            return split ? 
                splitAmounts[getTokenID(path[0].token)] : 
                parseEther(poolQuery.adjustNFTAmount(parseFloat(outputAmount), [...outputNFTPaths[index]].reverse()).toFixed(18))
        })

        const sharedPoolState: { [id: string]: PoolState } = await poolQuery.findSharedPools(paths)
        const slippageMultiplier = 10000 + (slippage * 100)

        for (let i = 0; i < paths.length; i++) {

            const path = paths[i]

            const [startToken, endToken] = [getTokenID(path[0].token), getTokenID((outputNFTPaths[i].length > 0 ? outputNFTPaths[i].slice(-1)[0] : path[path.length - 1]).token)]

            let resultAmount = Zero

            try {
                if(path.length == 1){
                    resultAmount = outputAmounts[i]
                } else {
                    const result = await poolQuery.query(path, outputAmounts[i], sharedPoolState, false)
                    resultAmount = result.amount
                    const newPoolStates = result.poolStates
                    Object.keys(sharedPoolState).forEach((pool, index) => {
                        sharedPoolState[pool] = {
                            xBalance: newPoolStates[index][0], 
                            yBalance: newPoolStates[index][1], 
                            totalSupply: newPoolStates[index][2], 
                            impAddress: newPoolStates[index][3]
                        }
                    })
                }
            } catch (_) {
                console.error('Proteus Query Error') 
            }

            inputAmounts[startToken] = inputAmounts[startToken].add(resultAmount);

            if (wrapOrUnwrap(path[0].token, path[path.length - 1].token)) {
                newMaxAmountsFrom[startToken][endToken] = resultAmount
            } else {
                newMaxAmountsFrom[startToken][endToken] = resultAmount.mul(slippageMultiplier).div(10000)
                if (!foundNonWrap) setFoundNonWrap(true)
            }
        }

        Object.keys(inputAmounts).forEach((inputToken) => {
            const inputAmount = inputAmounts[inputToken]
            if (inputAmount.isZero()) {
                delete newAmountsFrom[inputToken]
            } else {
                newAmountsFrom[inputToken] = formatUnits(inputAmount)
            }
            let summaryAmount = Zero
            Object.keys(newMaxAmountsFrom[inputToken]).forEach((outputToken) =>
                summaryAmount = summaryAmount.add(newMaxAmountsFrom[inputToken][outputToken])
            )
            newSummaryAmounts[inputToken] = formatUnits(summaryAmount)
        })

        setAmountsFrom(newAmountsFrom)
        setMaxAmountsFrom(newMaxAmountsFrom)
        setSummaryAmounts(newSummaryAmounts);

    }

    const checkForNFTs = async() => {
        const newUnavailableNFTs : any = {}
        for(let i = 0; i < tokensTo.length; i++){
            const outputToken = tokensTo[i]
            if(selectedNFTs[outputToken.symbol]){
                const nftIDs = selectedNFTs[outputToken.symbol].map((nft : NFT) => calculateWrappedTokenId(outputToken.address, nft.id))
                const balances = await ocean?.balanceOfBatch(nftIDs.map(() => outputToken.fractionalizer), nftIDs);
                const unavailableIDs = []
                for(let i = 0; i < balances.length; i++){
                    if(balances[i].isZero()){
                        const nftID = selectedNFTs[outputToken.symbol][i].id
                        unavailableIDs.push(nftID)
                    }
                }

                if(unavailableIDs.length)
                    newUnavailableNFTs[outputToken.symbol] = await buildNFTDisplays(outputToken, unavailableIDs)

            }
        }
        setUnavailableNFTs(newUnavailableNFTs)
    }

    const executeTransaction = () => {
        if (signer) {
          setConfirmDisabled(true);
          setTxState('Pending');

            const expectedAmounts = fromInputs ? minAmountsTo : maxAmountsFrom;
            const interactions = buildInteractions(
                tokensFrom, 
                tokensTo, 
                {...specifiedAmounts}, 
                expectedAmounts, 
                fromInputs, 
                splitAmounts, 
                {...selectedNFTs},
            )

            ShellV2.executeInteractions(ocean as types.Ocean, signer, interactions).then((response: any) => {
                setTxState('Submitted')

                toast.promise(response.wait(), {
                  loading: 'Transaction pending',
                  success: (data : any) => {
                    setTxHash(data.transactionHash);
                    setTxState('Success');
                    setTxSuccess(true);
                    return "Transaction success";
                  },
                  error: (data : any) => {
                    setTxHash(data.transactionHash)
                    setTxState('Error')
                    return "Transaction error"
                  }
                })
            }).catch(() => {
                setTxState('')
                setConfirmDisabled(false);
            });
        }
    }

    const closeModal = () => {
        setVisible(false);
        setTradeDisabled(txState === 'Submitted' || txState === 'Success');
        if (txState === 'Success') {
            dispatch(clearUserTransactions({user: walletAddress}))
            dispatch(clearCurrentPoints({address: walletAddress}))
            setTxState('');
        }
    }
    
    useEffect(() => {

        if (visible) {

            setConfirmDisabled(true);

            if (fromInputs) {

                setOutputLoading(true)

                const split = tokensTo.length > 1;
                const inputTokens = [...tokensFrom];
                const outputTokens = [...tokensTo];
                if (split) inputTokens.push(inputTokens[0]);

                updateOutputValues(inputTokens, outputTokens, split).then(() => {
                    setOutputLoading(false)
                    setConfirmDisabled(false)
                })

            } else {

                setInputLoading(true)

                const split = tokensFrom.length > 1;
                const outputTokens = [...tokensTo];
                const inputTokens = [...tokensFrom];
                if (split) outputTokens.push(outputTokens[0]);

                checkForNFTs()

                updateInputValues(outputTokens, inputTokens, split).then(() => {
                    setInputLoading(false)
                    setConfirmDisabled(false)
                })
            }
        }

    }, [visible])

    useEffect(() => {

        const refreshValues = setInterval(() => { // Requery values every 10 seconds

            if (visible && !confirmDisabled && (foundNonWrap || outputNFTCollections.length) && Object.keys(unavailableNFTs).length == 0) {

                setConfirmDisabled(true);

                if (fromInputs) {

                    setOutputLoading(true)

                    const split = tokensTo.length > 1;
                    const inputTokens = [...tokensFrom];
                    const outputTokens = [...tokensTo];
                    if (split) inputTokens.push(inputTokens[0]);

                    updateOutputValues(inputTokens, outputTokens, split).then(() => {
                        setTimeout(() => {
                            setOutputLoading(false)
                            setConfirmDisabled(false)
                        }, 500)
                    })

                } else {

                    setInputLoading(true)

                    const split = tokensFrom.length > 1;
                    const outputTokens = [...tokensTo];
                    const inputTokens = [...tokensFrom];
                    if (split) outputTokens.push(outputTokens[0]);

                    checkForNFTs()

                    updateInputValues(outputTokens, inputTokens, split).then(() => {
                        setTimeout(() => {
                            setInputLoading(false)
                            setConfirmDisabled(false)
                        }, 500)

                    })
                }
            }
        }, 10 * 1000);

        return () => clearInterval(refreshValues);

    }, [visible, confirmDisabled, unavailableNFTs])

    useEffect(() => {
      setTxSuccess(false);
      setTxState('');
      
      setQuotaTokens(
          tokensFrom.concat(tokensTo).filter(
              (token) => token.wrapped && parseFloat(userQuotas[getTokenID(token)]) > 0
          )
      )
    }, [amountsFrom, amountsTo])

    return (
        <StyledModal
            title={txState ? `Trade ${txState}` : 'Confirm Trade'}
            isVisible={visible}
            onClose={closeModal}
        >
            <ColumnWrapper>
                {tokensFrom.map((token, index) => {
                    return (
                        <TokenView key={getTokenID(token)} shape={index == tokensFrom.length - 1 ? 'bottom' : ''}>
                            <Row>
                                {inputLoading ?
                                    <StraightLoader /> :
                                    <div style={{ color: token.wrapped ? '#0ABCFD' : '#FFFFFF' }}>{
                                        `${formatDisplay(amountsFrom[getTokenID(token)] || '0')}`}
                                    </div>
                                }
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={token.icon} alt="logo" />
                                    {`${token.symbol}`}
                                </div>
                            </Row>
                        </TokenView>

                    )
                })}
                <Arrow>
                    <ArrowIcon />
                </Arrow>
                {tokensTo.map((token, index) => {
                    return (
                        <TokenView key={getTokenID(token)} shape={index == 0 ? 'top' : ''}>
                            <Row>
                                {outputLoading ?
                                    <StraightLoader /> :
                                    <div style={{ color: token.wrapped ? '#0ABCFD' : '#FFFFFF' }}>{
                                        `${formatDisplay(amountsTo[getTokenID(token)] || '0')}`}
                                    </div>
                                }
                                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <img src={token.icon} alt="logo" />
                                    {`${token.symbol}`}
                                </div>
                            </Row>
                        </TokenView>
                    )
                })}
                {
                    allNFTs.length > 0 ?
                    <>
                        <Row style={{margin: '20px 0 16px 0'}}>
                            <Label>Selected NFTs</Label>
                        </Row>
                        <SwiperContainer>
                            <NFTsSwiper
                                isConfirmation={true}
                                nftTokens={allNFTs}
                                navitagionTransform={{ left: "0", right: "0" }}
                            />
                        </SwiperContainer>
                    </>
                    :
                    <Accountant double={tokensFrom.length > 1 || tokensTo.length > 1} numQuotaTokens={quotaTokens.length} />
                }
                {quotaTokens.length > 0 &&
                <div style={{marginBottom: '12px'}}>
                    <Row style={{marginBottom : '12px'}}>
                        <Label>Balance / Quota Changes</Label>
                    </Row>
                    {quotaTokens.map((token) => {
                        const tokenID = getTokenID(token)
                        const quota = Number(userQuotas[tokenID])
                        const initialBalance = Number(userBalances[token.oceanID] ?? 0)
                        let newBalance = initialBalance
                        let displayDecimals = maxTokenDecimals(tokenID)
                        if(tokensFrom.includes(token)){
                            newBalance -= Number(amountsFrom[tokenID])
                        } else {
                            newBalance += Number(amountsTo[tokenID])
                        }
                        let quotaColor = ''
                        if(newBalance >= quota && newBalance > initialBalance){
                            quotaColor = 'green'
                        } else if(initialBalance >= quota && newBalance < quota){
                            quotaColor = 'red'
                        } 
                        
                        return(
                            <Row key={getTokenID(token)} style={{marginBottom: '4px'}}>
                                <Value style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                    <img style={{maxHeight: '24px', maxWidth: '24px'}} src={token.icon} alt="logo" />
                                    {getTokenID(token)}
                                </Value>
                                <div style={{display: 'flex', alignItems: 'center'}}>                                    
                                    <Value>{formatDisplayShorthand(initialBalance, displayDecimals)}</Value>
                                    <Quota color={quotaColor}>{'\u2192'} {formatDisplayShorthand(newBalance, displayDecimals)} / {formatDisplayShorthand(quota, displayDecimals)}</Quota>
                                </div>
                            </Row>
                        )
                    })}
                </div>
                }
                {foundNonWrap && 
                    <Row style={{marginBottom: '12px'}}>
                        <Label>Slippage Tolerance</Label>
                        <Value>{slippage.toFixed(2)}%</Value>
                    </Row>
                }
                <Row style={{marginBottom: '12px'}}>
                    <Label>{fromInputs ? 'Minimum Received' : "Maximum Sent"}</Label>
                    <Column>
                        {(fromInputs ? tokensTo : tokensFrom).map((token) => {
                            const tokenID = getTokenID(token)
                            return (
                                <Value key={tokenID} style={{ marginBottom: '6px', textAlign: 'right' }}>
                                    {formatDisplay(summaryAmounts[tokenID] ?? '0')} {tokenID}
                                </Value>
                            )
                        })}
                    </Column>
                </Row>
                {
                    (!txState || txState == 'Pending') &&
                    <Summary>
                        <SummaryText>
                            Trade {!fromInputs && foundNonWrap && <span style={{ textDecoration: 'underline' }}>at most</span>} {tokensFrom.map((token, index) => {
                                if (token.wrapped) {
                                    wrappedTokens.push(token.wrapped)
                                }
                                const tokenID = getTokenID(token)
                                return (
                                    <span key={index}>
                                        <SummaryColor data-testid={`summary-from-${tokenID}-${index}`} color={tokenColors[token.symbol]}>
                                            {`${formatDisplay(summaryAmounts[tokenID] || '0')} ${tokenID}`}
                                        </SummaryColor>
                                        {index !== tokensFrom.length - 1 && ' and '}
                                    </span>
                                )
                            })} for {fromInputs && foundNonWrap && <span style={{ textDecoration: 'underline' }}>at least</span>} {tokensTo.map((token, index) => {
                                if (token.wrapped) {
                                    wrappedTokens.push(token.wrapped)
                                }
                                const tokenID = getTokenID(token)
                                return (
                                    <span key={index}>
                                        <SummaryColor data-testid={`summary-to-${tokenID}-${index}`} color={tokenColors[token.symbol]}>
                                            {`${formatDisplay(summaryAmounts[tokenID] || '0')} ${tokenID}`}
                                        </SummaryColor>
                                        {index !== tokensTo.length - 1 && ' and '}
                                    </span>
                                )
                            })}
                        </SummaryText>
                    </Summary>
                }
                {
                    txState && txState != 'Pending' && 
                    <>
                        {txState != 'Submitted' && <Label data-testid="success-label" style={{margin: '0 auto 12px auto', color: '#7D7D97'}}>{txState == 'Success' ? 'Success!' : 'Error'}</Label>}
                        <CircleContainer>
                            {txState == 'Success' ? <ConfirmIcon/> : txState == 'Error' ? <ErrorIcon/> : <Spinner/>}
                        </CircleContainer>
                        {txState != 'Submitted' && <ExplorerLink href={CONNECTED_CHAIN_EXPLORER + 'tx/' + txHash} target="_blank">{`View in Explorer \u2192`}</ExplorerLink>}
                    </>
                    
                }
                {
                (txState == '' || txState == 'Pending') &&
                    <ActionButton data-testid="confirm-swap-btn" onClick={executeTransaction} disabled={confirmDisabled}>
                        <ConfirmIcon/>
                        Confirm Trade
                    </ActionButton>
                }
                {
                    txState == 'Submitted' &&
                    <Label data-testid="waiting-label" style={{margin: '16px auto 4px auto', color: '#7D7D97'}}>Waiting for blockchain confirmation...</Label>
                }
            </ColumnWrapper>
            <NFTWarning 
                nftTokens={Object.values(unavailableNFTs).flat()} 
                showModal={Object.keys(unavailableNFTs).length > 0}
                canProceed={outputNFTCollections.filter((collection) => selectedNFTs[collection]?.length == unavailableNFTs[collection]?.length).length == 0}
                onClose={() => {
                    closeModal()
                    const newSelectedNFTs = {...selectedNFTs}
                    Object.keys(unavailableNFTs).forEach((collection) => {
                        newSelectedNFTs[collection] = selectedNFTs[collection].filter((nft : any) => unavailableNFTs[collection].map((nft) => nft.id).indexOf(nft.id) == -1)
                    })
                    setSelectedNFTs(newSelectedNFTs)
                    setUnavailableNFTs({})

                    
                }}
                onProceed={() => {
                    const newSelectedNFTs = {...selectedNFTs}
                    for(let i = 0; i < outputNFTCollections.length; i++){
                        const collection = outputNFTCollections[i]
                        const unavailableIDs = unavailableNFTs[collection]?.map((nft) => nft.id) ?? []
                        const newCollectionNFTs = selectedNFTs[collection].filter((nft : NFT) => !unavailableIDs.includes(nft.id))
                        selectedNFTs[collection] = newCollectionNFTs
                        newSelectedNFTs[collection] = newCollectionNFTs
                        specifiedAmounts[collection] = newCollectionNFTs.length.toString()
                    }

                    setSelectedNFTs(newSelectedNFTs)
                    setUnavailableNFTs({})

                    updateInputValues([...tokensTo], [...tokensFrom], false).then(() => {
                        setTimeout(() => {
                            setInputLoading(false)
                            setConfirmDisabled(false)
                        }, 500)
                    })
                }}
            />
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
  height: fit-content;
  max-width: 520px;

  ${Media.mobile} {
    height: 95%;
    max-width: 100%;
    margin-top: 5.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

const TokenView = styled.div<{ shape?: string }>`
  position: relative;
  border-radius: 16px;
  z-index: 1;
  max-width: 99.5%;
  padding-left: 16px;
  padding-right: 16px;

  & + & {
    margin-top: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #151530;
    border: 1px solid #1E2239;
    border-radius: 16px;
    z-index: -1;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnWrapper = styled(Column)`
    margin-top: 12px;

    ${Media.mobile} {
        flex-basis: 100%;
    }
`

const Arrow = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 8px;
    color: white;

    & > svg {
        width: 24px;
        height: 24px;
        color: #00BDFF;
    }
`

const SwiperContainer = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 16px;

    ${Media.mobile} {
        margin-bottom: 18px;
    }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: -0.03em;
  color: #FFFFFF;

  img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  text-align: left;
  color: #7D7D97;
  white-space: nowrap;
  letter-spacing: -0.02em;
`;

const Value = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #FFFFFF;
  white-space: nowrap;
  letter-spacing: 0.02em;
`;

const Quota = styled.p<{ color?: string }>`
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  color: #FFFFFF;
  white-space: nowrap;
  margin-left: 4px;
  letter-spacing: 0.02em;
  ${({ color }) => color === 'green' && css`
    color: #7ADEB9;
  `};
  ${({ color }) => color === 'red' && css`
    color: #FF5349;
  `};
`;

const Summary = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`

const SummaryText = styled.p`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`

const SummaryColor = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`

const CircleContainer = styled.div`
  width: 64px;
  height: 64px;
  margin: 0px auto;
  color: #00BDFF;

  img {
    width: 64px;
    height: 64px;
  }
`

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #151530;
    color: #464659;
  }

  ${Media.tablet} {
    height: 66px;
    margin: 16px auto;
  }
`;

const ExplorerLink = styled.a`
    font-weight: 100;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #7D7D97;
    white-space: nowrap;
    letter-spacing: -0.02em;
    margin: 12px auto 2px auto;

    :hover {
        color: #00BDFF;
    }
`