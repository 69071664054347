
import React, { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GlobalStyle } from './GlobalStyle'
import { WagmiProvider } from './WagmiProvider'

interface Props {
  children: ReactNode
}

export function Providers(props: Props) {
  return (
    <WagmiProvider>
      <BrowserRouter>
        <GlobalStyle />
        {props.children}
      </BrowserRouter>
    </WagmiProvider>
  )
}