import { BigNumberish } from "@ethersproject/bignumber";
import { HashZero as NullMetadata } from '@ethersproject/constants'
import { wrapERC20, unwrapERC20, computeInputAmount, computeOutputAmount, wrapEther, unwrapEther, wrapERC721, unwrapERC721, wrapERC1155, unwrapERC1155 } from './ocean/interactions';
import { Interaction } from "./ocean/types";
import { ETH_ADDRESS } from '../constants/addresses'
import { isNFTCollection, tokenMap } from "../placeholders/tokens";
import { Edge, getTokenID, LiquidityGraph } from "./LiquidityGraph";
import { calculateWrappedTokenId } from "./ocean/utils";
import { hexZeroPad } from "ethers/lib/utils";

export type InteractionCallback = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => Interaction

export const liquidityGraph = new LiquidityGraph();

export type InteractionNetwork = {[tokenID : string]: {[neighbor : string]: InteractionCallback}}

export const buildInteractionNetwork = (graph : Record<string, Edge[]>) => {
    const interactionNetwork : InteractionNetwork = {}

    for(let token of Object.values(tokenMap)){
        const neighbors : any = {}
        graph[getTokenID(token)].forEach((neighbor) => {
            if(isNFTCollection(token)){
                if(token.is1155){
                    if(neighbor.action == 'Wrap'){
                        neighbors[neighbor.token.symbol] = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => 
                            wrapERC1155(token.address, nftID!, amount)
                    } else if(neighbor.action == 'Unwrap'){
                        neighbors[neighbor.token.symbol] = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => 
                            unwrapERC1155(token.address, nftID!, amount)
                    } else {
                        neighbors[getTokenID(neighbor.token)] = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            return compute(
                                // @ts-ignore
                                token.fractionalizer!, 
                                calculateWrappedTokenId(token.address, nftID!), 
                                neighbor.token.oceanID!, 
                                amount, 
                                hexZeroPad(nftID!, 32) ?? NullMetadata
                            )
                        }
                    }
                } else {
                    if(neighbor.action == 'Wrap'){
                        neighbors[neighbor.token.symbol] = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => 
                            wrapERC721(token.address, nftID!)
                    } else if(neighbor.action == 'Unwrap'){
                        neighbors[neighbor.token.symbol] = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => 
                            unwrapERC721(token.address, nftID!)
                    } else {
                        neighbors[getTokenID(neighbor.token)] = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            return compute(
                                // @ts-ignore
                                token.fractionalizer, 
                                calculateWrappedTokenId(token.address, nftID!), 
                                neighbor.token.oceanID!, 
                                amount, 
                                hexZeroPad(nftID!, 32) ?? NullMetadata
                            )
                        }
                    }
                }
            } else {
                if(neighbor.action == 'Wrap'){
                    neighbors[neighbor.token.symbol] = (amount: BigNumberish, direction: boolean, slippage?: string) => 
                        token.address == ETH_ADDRESS ? wrapEther(amount) : wrapERC20(token.address, amount)
                } else if(neighbor.action == 'Unwrap'){
                    neighbors[neighbor.token.symbol] = (amount: BigNumberish, direction: boolean, slippage?: string) => 
                        token.address == ETH_ADDRESS ? unwrapEther(amount) : unwrapERC20(token.address, amount)
                } else {
                    if(isNFTCollection(neighbor.token)){
                        neighbors[getTokenID(neighbor.token)] = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            return compute(
                                // @ts-ignore
                                neighbor.token.fractionalizer, 
                                token.oceanID!, 
                                calculateWrappedTokenId(neighbor.token.address, nftID!), 
                                amount, 
                                hexZeroPad(nftID!, 32) ?? NullMetadata
                            )
                        }
                    } else {
                        neighbors[getTokenID(neighbor.token)] = (amount: BigNumberish, direction: boolean, slippage?: string) => {
                            const compute = direction ? computeOutputAmount : computeInputAmount;
                            // @ts-ignore
                            return compute(tokenMap[neighbor.pool].pool, token.oceanID!, neighbor.token.oceanID!, amount, slippage ?? NullMetadata)
                        }
                    }
                    
                }
            }
        })
        interactionNetwork[getTokenID(token)] = neighbors
    }
    return interactionNetwork
}

export const findPath = (
    start: string,
    end: string,
    fromInputs: boolean,
): any => {

    const interactionNetwork = buildInteractionNetwork(liquidityGraph.adjustGraph([tokenMap[start], tokenMap[end]]))

    const visited: { [tokenID : string]: boolean } = Object.fromEntries(Object.entries(interactionNetwork).map(([k, v]) => [k, false]));
    const parents: { [tokenID : string]: string } = Object.fromEntries(Object.entries(interactionNetwork).map(([k, v]) => [k, '']));
    visited[start] = true
    parents[start] = start
    const queue = [start]

    while (queue.length > 0) {
        // O(n), not a problem for now.
        const key: string | undefined = queue.shift();
        if (key !== undefined) {
            const vertex = interactionNetwork[key]
            Object.keys(vertex).map((adj: string | string) => {
                if (visited[adj] !== undefined && visited[adj] === false) {
                    visited[adj] = true;
                    parents[adj] = key;
                    queue.push(adj);
                }
            })
        } else {
            throw new Error("Value in route queue was undefined");
        }
    }

    const interactions: InteractionCallback[] = []
    const explanation: any[] = []
    let current: string | undefined = end;
    while (current !== undefined && parents[current] !== undefined && parents[current] != current) {
        const parent: string | undefined = parents[current]
        if (parent !== undefined) {
            if (fromInputs) {
                const interactionCb: InteractionCallback | undefined = interactionNetwork[parent][current]
                if (interactionCb !== undefined) {
                    interactions.unshift(interactionCb)
                    // explanation.unshift(`from ${parent} to ${current}`);
                    explanation.unshift(tokenMap[parent]);
                } else {
                    throw new Error("Failed to find edge")
                }
            } else {
                const interactionCb: InteractionCallback | undefined = interactionNetwork[current][parent]
                if (interactionCb !== undefined) {
                    interactions.unshift(interactionCb)
                    // explanation.unshift(`from ${current} to ${parent}`);
                    explanation.unshift(tokenMap[parent]);
                } else {
                    throw new Error("Failed to find edge")
                }
            }
        } else {
            throw new Error("Failed to find edge")
        }

        current = parents[current]
    }
    return {interactions: interactions, tokens: explanation};
}