
import { Fractionalizer1155ABI } from "../constants/ABI/Fractionalizer1155ABI";
import { Token, tokens, ShellToken, shellTokens, isShellToken, tokenMap, isDefaultShellToken, nftCollections, isNFTCollection, lbpTokens } from "../placeholders/tokens";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";
import { BigNumber, BigNumberish } from "ethers";
import { calculateWrappedTokenId } from "./ocean/utils";
import { Zero } from '@ethersproject/constants'
import { infuraId } from "../providers/WagmiProvider";
import { Interaction } from "./ocean/types";
import { extract1155Data, multicall } from "./nftHelpers";
import { LBPPoolABI } from "@/constants/ABI/LBPPoolABI";
import { LiquidityPoolABI } from "@/constants/ABI/LiquidityPoolABI";
export interface Edge {
    token: Token
    pool: string
    action: string
}

export const getTokenID = (token: Token | ShellToken): string => {
    return isShellToken(token) ? token.name : token.symbol
}

export type InteractionCallback = (amount: BigNumberish, direction: boolean, slippage?: string, nftID?: string) => Interaction

const LiquidityVertices = Object.keys(tokenMap)
const LiquidityVerticesMap = LiquidityVertices.reduce(
    (acc, next) => ({ ...acc, [next]: null }),
    {} as Record<string, null>,
);

export type LiquidityVertex = typeof LiquidityVertices[number];
export const isLiquidityVertex = (value: string): value is LiquidityVertex => {
    return value in LiquidityVerticesMap;
}
export type InteractionNetwork = {
    [LV in LiquidityVertex]: {
        [LV in LiquidityVertex]?: InteractionCallback
    }
}
export class LiquidityGraph {

    graph: Record<string, Edge[]> = {};

    constructor() {
        // multicall = new Multicall({ nodeUrl: `https://arbitrum-goerli.infura.io/v3/${infuraId}`, tryAggregate: true });

        const nftAddresses = new Set(nftCollections.map((collection) => collection.address))

        tokens.forEach((token) => {
            if(nftAddresses.has(token.address)){
                const wrappedNFTCollection = nftCollections.filter((collection) => collection.wrapped && collection.address == token.address)[0]
                this.graph[token.symbol] = [{
                    token: tokenMap[wrappedNFTCollection.symbol],
                    pool: token.symbol,
                    action: 'Unfractionalize'
                }]
            } else {
                this.graph[token.symbol] = [{
                    token: tokenMap[token.wrapped ? token.symbol.substring(2) : 'sh' + token.symbol], 
                    pool: '', 
                    action: token.wrapped ? 'Unwrap' : 'Wrap'
                }];
            }
                
        })

        nftCollections.forEach(async (collection) => {
            this.graph[collection.symbol] = [
                {
                    token: tokenMap[collection.wrapped ? collection.symbol.substring(2) : 'sh' + collection.symbol], 
                    pool: '', 
                    action: collection.wrapped ? 'Unwrap' : 'Wrap'
                }
            ];

            if(collection.wrapped && collection.fractionalizer != ''){
                if(collection.is1155){
                    const contractCallContext: ContractCallContext[] = [
                        {
                            reference: 'Fractionalizer',
                            contractAddress: collection.fractionalizer!,
                            abi: Fractionalizer1155ABI as any,
                            calls: Array.from(Array(collection.maxSupply).keys()).map((nftID) => {
                                return {
                                    reference: "fungibleTokenIds",
                                    methodName: "fungibleTokenIds",
                                    methodParameters: [calculateWrappedTokenId(collection.address, BigNumber.from(nftID))]
                                }
                            })
                        }
                    ];

                    // const results : ContractCallResults = await 
                    multicall.call(contractCallContext).then((results : ContractCallResults) => {
                        results.results['Fractionalizer'].callsReturnContext.forEach((callResult, index) => {
                            if(!Zero.eq(callResult.returnValues[0])){

                                const symbol = 'fr' + collection.symbol.substring(2) + '-' + index.toString()

                                const lbpToken = lbpTokens.filter(lbpToken => lbpToken.symbol == symbol)[0]

                                let fungibleToken : any
                                let lpToken : any

                                if(lbpToken){
                                    fungibleToken = lbpToken
                                    fungibleToken.oceanID = callResult.returnValues[0]
                                    lpToken = tokenMap[lbpToken.poolName]
                                } else {
                                    fungibleToken = {
                                        name: 'Fractional ' + collection.name,
                                        symbol: symbol,
                                        address: collection.address,
                                        wrapped: true,
                                        oceanID: callResult.returnValues[0],
                                        icon: ''
                                    }
                                    lpToken = shellTokens.filter((lpToken) => lpToken.tokens.includes(fungibleToken.symbol))[0] 
                                }

                                if(collection.symbol.substring(2) !== 'BKB'){
                                    this.graph[collection.symbol].push({
                                        token: fungibleToken,
                                        pool: fungibleToken.symbol,
                                        action: 'Fractionalize'
                                    })
                                }

                                this.graph[fungibleToken.symbol] = [{
                                    token: collection,
                                    pool: fungibleToken.symbol,
                                    action: 'Unfractionalize'
                                }]
                                
                                tokenMap[fungibleToken.symbol] = fungibleToken

                                if(lpToken){

                                    this.graph[lpToken.name] = []

                                    const hasFirstToken = fungibleToken.symbol == lpToken.tokens[0]
                                    const hasSecondToken = fungibleToken.symbol == lpToken.tokens[1]

                                    const childTokenOne = hasFirstToken ? fungibleToken : tokenMap[lpToken.tokens[0]]
                                    const childTokenTwo = hasSecondToken ? fungibleToken : tokenMap[lpToken.tokens[1]]
                                    const childTokenOneID = (hasFirstToken || isShellToken(childTokenOne) || nftAddresses.has(childTokenOne.address) ? '' : 'sh') + getTokenID(childTokenOne)
                                    const childTokenTwoID = (hasSecondToken || isShellToken(childTokenTwo) || nftAddresses.has(childTokenTwo.address) ? '' : 'sh') + getTokenID(childTokenTwo)

                                    this.graph[lpToken.name].push({token: hasFirstToken ? childTokenOne : tokenMap[childTokenOneID], pool: lpToken.name, action: 'Withdraw'})
                                    this.graph[lpToken.name].push({token: hasSecondToken ? childTokenTwo : tokenMap[childTokenTwoID], pool: lpToken.name, action: 'Withdraw'})
                                    
                                    if(collection.symbol.substring(2) !== 'BKB'){
                                        this.graph[childTokenOneID].push({token: hasSecondToken ? childTokenTwo : tokenMap[childTokenTwoID], pool: lpToken.name, action: 'Swap'})
                                        this.graph[childTokenOneID].push({token: tokenMap[lpToken.name], pool: lpToken.name, action: 'Deposit'})
                                        this.graph[childTokenTwoID].push({token: hasFirstToken ? childTokenOne : tokenMap[childTokenOneID], pool: lpToken.name, action: 'Swap'})   
                                        this.graph[childTokenTwoID].push({token: tokenMap[lpToken.name], pool: lpToken.name, action: 'Deposit'})    
                                    }
                                }
                            }
                        })
                    })

                } else {
                    const fungibleToken = Object.values(tokenMap).filter((token) => token.wrapped && token.address == collection.address)[0]
                    this.graph[collection.symbol].push({
                        token: tokenMap[fungibleToken.symbol],
                        pool: fungibleToken.symbol,
                        action: 'Fractionalize'
                    })
                }
            }
        })

        shellTokens.forEach((lpToken) => {
            this.graph[lpToken.name] = []

            const childTokenOne = tokenMap[lpToken.tokens[0]]
            const childTokenTwo = tokenMap[lpToken.tokens[1]]
            if(childTokenOne == undefined || childTokenTwo == undefined) return

            const childTokenOneID = (isShellToken(childTokenOne) || nftAddresses.has(childTokenOne.address) ? '' : 'sh') + getTokenID(childTokenOne)
            const childTokenTwoID = (isShellToken(childTokenTwo) || nftAddresses.has(childTokenTwo.address) ? '' : 'sh') + getTokenID(childTokenTwo)

            this.graph[lpToken.name].push({token: tokenMap[childTokenOneID], pool: lpToken.name, action: 'Withdraw'})
            this.graph[lpToken.name].push({token: tokenMap[childTokenTwoID], pool: lpToken.name, action: 'Withdraw'})
            this.graph[childTokenOneID].push({token: tokenMap[childTokenTwoID], pool: lpToken.name, action: 'Swap'})
            this.graph[childTokenOneID].push({token: tokenMap[lpToken.name], pool: lpToken.name, action: 'Deposit'})
            this.graph[childTokenTwoID].push({token: tokenMap[childTokenOneID], pool: lpToken.name, action: 'Swap'})   
            this.graph[childTokenTwoID].push({token: tokenMap[lpToken.name], pool: lpToken.name, action: 'Deposit'})
        })

        const currentTime = Math.floor(Date.now() / 1000)

        lbpTokens.filter(lbpToken => !extract1155Data(lbpToken.symbol)).forEach((lbpToken) => {
            this.graph[lbpToken.symbol] = [{
                token: tokenMap['sh' + lbpToken.symbol], 
                pool: '', 
                action: 'Wrap'
            }];
            
            this.graph[lbpToken.poolName] = []

            const childTokenOneID = 'sh' + lbpToken.symbol
            const childTokenTwoID = 'sh' + lbpToken.pairToken

            this.graph[lbpToken.poolName].push({token: tokenMap[childTokenOneID], pool: lbpToken.poolName, action: 'Withdraw'})
            this.graph[lbpToken.poolName].push({token: tokenMap[childTokenTwoID], pool: lbpToken.poolName, action: 'Withdraw'})
            this.graph[childTokenOneID].push({token: tokenMap[childTokenTwoID], pool: lbpToken.poolName, action: 'Swap'})
            this.graph[childTokenOneID].push({token: tokenMap[lbpToken.poolName], pool: lbpToken.poolName, action: 'Deposit'})
            this.graph[childTokenTwoID].push({token: tokenMap[childTokenOneID], pool: lbpToken.poolName, action: 'Swap'})   
            this.graph[childTokenTwoID].push({token: tokenMap[lbpToken.poolName], pool: lbpToken.poolName, action: 'Deposit'})
        })

        const contractCallContext: ContractCallContext[]  = lbpTokens.map((lbpToken) => {
            return {
                reference: lbpToken.symbol,
                contractAddress: lbpToken.poolAddress,
                abi: LiquidityPoolABI as any,
                calls: [{
                    reference: "implementation",
                    methodName: "implementation",
                    methodParameters: []
                }]
            }
        })

        multicall.call(contractCallContext).then((results : ContractCallResults) => {
            const lbpParamsCallContext = Object.keys(results.results).map((tokenID) => {
                return ({
                    reference: tokenID,
                    contractAddress: results.results[tokenID].callsReturnContext[0].returnValues[0],
                    abi: LBPPoolABI as any,
                    calls: [{
                        reference: "params",
                        methodName: "params",
                        methodParameters: []
                    }]
                })
            })

            multicall.call(lbpParamsCallContext).then((results : ContractCallResults) => {

                for(let index = lbpTokens.length - 1; index >= 0; index--){
                    const lbpToken = lbpTokens[index];
                    if(results.results[lbpToken.symbol].callsReturnContext[0].returnValues.length == 0){ // Pool is no longer an LBP
                        lbpToken.status = 'Ended'
                        //@ts-ignore 
                        shellTokens.push(tokenMap[lbpToken.poolName])
                        if(!extract1155Data(lbpToken.symbol)){
                            tokens.push({
                                name: lbpToken.name,
                                symbol: lbpToken.symbol,
                                address: lbpToken.address,
                                wrapped: false,
                                icon: lbpToken.icon
                            })
                        }
                        lbpTokens.splice(index, 1)
                        continue
                    }
                    const times = results.results[lbpToken.symbol].callsReturnContext[0].returnValues.slice(-3)
                    const [startTime, endTime] = [parseInt(times[0].hex), parseInt(times[1].hex)]
                    
                    if(currentTime > endTime){
                        lbpToken.status = 'Ended'
                        //@ts-ignore 
                        shellTokens.push(tokenMap[lbpToken.poolName])
                        if(!extract1155Data(lbpToken.symbol)){
                            tokens.push({
                                name: lbpToken.name,
                                symbol: lbpToken.symbol,
                                address: lbpToken.address,
                                wrapped: false,
                                icon: lbpToken.icon
                            })
                        }
                        lbpTokens.splice(index, 1)
                    } else if(currentTime > startTime) {
                    lbpToken.status = 'Ongoing'
                    } else {
                        lbpToken.status = 'Upcoming'
                    }
                }
            })
        })
    }

    findPath = (startToken: Token | ShellToken, endToken: Token | ShellToken) => {

        const graph = this.adjustGraph([startToken, endToken])
        
        if(isDefaultShellToken(startToken) || isDefaultShellToken(endToken)) return []
    
        const queue: [string, Edge[]][] = [[getTokenID(startToken), [{token: startToken, pool: '', action: ''}]]];
        const visited = new Set();
    
        while (queue.length > 0) {
            let current = queue.shift();
            if (current) {
                let [tokenID, path] = current
                visited.add(tokenID)
                for (let i = 0; i < graph[tokenID].length; i++) {
                    let node = graph[tokenID][i];
                    let nodeID = getTokenID(node.token)
    
                    if (nodeID == getTokenID(endToken)) {
                        path.push(node)
                        return path
                    } else if (!visited.has(nodeID)) {
                        visited.add(nodeID)
                        let newPath = [...path || []]
                        newPath.push(node)
                        queue.push([nodeID, newPath])
                    }
                }
            }
        }
        return []
    }

    isSingleStepPath = (startToken: Token | ShellToken, endToken : Token | ShellToken) => {
        for(const neighbor of this.graph[getTokenID(startToken)]){
            if(getTokenID(neighbor.token) == getTokenID(endToken)){
                return true
            }
        }
        return false
    }

    adjustGraph = (tokens : any[]) => {
        const adjustedGraph = {...this.graph}
        tokens.forEach((token) => {
            if(isNFTCollection(token) && token.is1155){
                const wrappedID = (token.wrapped ? '' : 'sh') + token.symbol 
                const fractionalTokens = adjustedGraph[wrappedID].filter((neighbor) => neighbor.action == 'Fractionalize')
                fractionalTokens.forEach((fractionalToken) => {
                    const fractionalTokenID = fractionalToken.token.symbol
                    if(!fractionalTokenID.includes(token.id1155!.toString())){
                        adjustedGraph[fractionalTokenID].forEach((neighbor) => {
                            const neighborID = getTokenID(neighbor.token)
                            adjustedGraph[neighborID] = adjustedGraph[neighborID]?.filter((neighbor) => fractionalTokenID !== neighbor.token.symbol)
                        })
                        adjustedGraph[fractionalTokenID] = []
                    }
                })
            }
        })
        return adjustedGraph;
    }
}