export const DISPLAY_DECIMALS = 6;

export const formatDisplay = (balance = '', numDecimals = DISPLAY_DECIMALS): string => {

    const balanceNum = parseFloat(balance)
    if(Number.isNaN(balanceNum)) return '0'
    if(balanceNum > 0 && balanceNum < 10**(-numDecimals)){
        if(numDecimals == 0) return '<1'
        return '<0.' + "0".repeat(numDecimals < 2 ? 1 : numDecimals - 1) + '1'
    } else {
        const multiplier = Math.pow(10, numDecimals),
            adjustedNum = balanceNum * multiplier,
            truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
        return (truncatedNum / multiplier).toLocaleString(undefined, {maximumFractionDigits: numDecimals});
    }
}

export const formatDisplayShorthand = (value : number, numDecimals = 0, thousandsThreshold = 1e5, unit = '') => {

    if(value >= 1e9){
        return formatDisplay((value / 1e9).toString(), 2) + 'B'
    } else if(value >= 1e6){
        return formatDisplay((value / 1e6).toString(), 2) + 'M'
    } else if(value >= thousandsThreshold){
        return formatDisplay((value / 1000).toString(), 2) + 'K'
    } else {
        return unit + formatDisplay(value.toString(), numDecimals)
    }
}

export function timestampToDate(timestamp: string) {
    if(!timestamp) return ''
    const date = new Date(Number(timestamp) * 1000);
    return date.toDateString().substring(4) + ', ' + date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
}