export const tokenColors: Record<string, string> = {
  'USDC.e': '#3E73C4',
  'shUSDC.e': '#3E73C4',
  USDT: '#26A17B',
  shUSDT: '#26A17B',
  DAI: '#F3B631',
  shDAI: '#F3B631',
  WBTC: '#F09242',
  shWBTC: '#F09242',
  ETH: '#687ce3',
  shETH: '#687ce3',
  wstETH: '#48A0F8',
  shwstETH: '#48A0F8',
  shLP: '#0ABCFD',
  TOUCAN: '#AFF8FA',
  shTOUCAN: '#AFF8FA',
  ARB: '#2f3740',
  shARB: '#2f3740',
  TOUCOIN: '#D6B974',
  MAGIC: '#CA3A31',
  shMAGIC: '#CA3A31',
  SMOL: '#7752DE',
  shSMOL: '#7752DE',
  SMOLCOIN: '#7752DE',
  COLLAB: '#F6C349',
  shCOLLAB: '#F6C349',
  STG: '#666666',
  shSTG: '#666666',
  REUNI: '#CC8CC0',
  shREUNI: '#CC8CC0',
  lbp: '#00BDFF',
  SHELL: '#00BDFF',
  BKB: '#EFA05B',
  shBKB: '#EFA05B',
  ACCT: '#EFA05B',
  shACCT: '#EFA05B'
}