import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import { useWidthBreakpoint } from '../../hooks';
import { maxTokenDecimals } from '../../placeholders/tokens';
import { breakpoints, Media } from '../../styles';
import { formatDisplay, formatDisplayShorthand } from '../../utils/formatDisplay';

interface BalanceInfoProps {
  tokenID: string,
  balance: string,
  quota: number,
  showMax: boolean,
  balanceClick: (percentage: number) => void,
  isNFTCollection: boolean
  isShrunk: boolean
}

export const BalanceInfo = ({ tokenID, balance, quota, showMax, balanceClick, isNFTCollection, isShrunk }: BalanceInfoProps) => {
  const [showOptions, setShowOptions] = useState(false)

  const percentages = [0.25, 0.5]

  const displayDecimals = maxTokenDecimals(tokenID)

  const MaxOptions = () => {
    return (
      <Row onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
        {showOptions &&
          percentages.map((percentage, index) => {
            return (
              <MaxButton key={percentage.toString()} onClick={() => balanceClick(percentage)}>
                {`${percentage * 100}%`}
              </MaxButton>
            )
          })
        }
        <MaxButton onClick={() => balanceClick(1)}>
          MAX
        </MaxButton>
      </Row>
    );
  };

  const balanceDisplay = isShrunk ? 
                        formatDisplayShorthand(parseFloat(balance), displayDecimals, 1000).replace(/,/g, '.') : 
                        formatDisplay(balance, displayDecimals)

  const quotaDisplay = quota > 0 ? `/ ${formatDisplayShorthand(quota, displayDecimals, 1000)} ` : ' '

  return (
    <Row>
      {!isShrunk && showMax && !isNFTCollection && <MaxOptions />}
      <BalanceWrapper>
        <Label className={isShrunk ? 'shrunk' : ''}>{quota > 0 ? 'Balance / Quota' : 'Balance'}</Label>
        <Row>
          <Amount data-testid={`amount-label-${tokenID}`} filledQuota={quota > 0 && parseFloat(balance) > quota} className={isShrunk ? 'shrunk' : ''}>
            {balanceDisplay + quotaDisplay + tokenID}
          </Amount>
        </Row>
        {isShrunk && showMax && !isNFTCollection && <Label style={{color: '#00bdff'}} onClick={() => balanceClick(1)}>{'Max'}</Label>}
      </BalanceWrapper>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  align-items: end;
  justify-content: right;
`;

const BalanceWrapper = styled.div`
  display: flex;
  align-items: right;
  flex-direction: column;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      justify-content: flex-end;
      align-items: unset;
      flex-direction: column;
      gap: 4px;
      flex-wrap: wrap;
    `
  };

  
`;

const Label = styled.p`
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #7D7D97;
  white-space: nowrap;
  
  ${props =>
    props.className?.includes('shrunk') &&
    css`
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 0px;
    `};
`;

const Amount = styled.p<{ filledQuota: boolean }>`
  font-weight: 500;
  min-width: 52px;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #FFFFFF;
  white-space: nowrap;
  padding-bottom: 3px;
  color: ${({ filledQuota }) => filledQuota ? '#7ADEB9' : '#FFFFFF'};

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      min-width: unset;
      font-size: 12px;
      line-height: 14px;
      padding-bottom: 0px;
    `};
`;

// const MaxButton = styled.button`
//   margin-right: 10px;
//   border: none;
//   border: solid 2px white;
//   border-radius: 6px;
//   background: rgba(255, 255, 255,0);
//   color: white;
//   font-size: 10px;
//   font-weight: 500;
//   line-height: 17px;

//   :hover {
//     background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
//     color: #000E47;
//     border: solid 2px #000E47;
//   }
// `
const MaxButton = styled.button`
  margin-right: 10px;
  background: rgba(0, 189, 255, 0.1);
  border: 1px solid #00bdff;
  border-radius: 6px;
  color: #00bdff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  :hover {
    background: #00bdff;
    border-color: transparent;
    border-radius: 6px;
    color: #0a0e27;
  }
`;