import React from 'react'
import Foreground from '../assets/accountant.png'
import Background from '../assets/accountant-bg.png'
import styled, { css } from 'styled-components'
import { breakpoints, Media } from '../styles'
import { useWidthBreakpoint } from '../hooks'

export const Accountant = (props : any) => {

    const { double, numQuotaTokens } = props
    const isSmallMobile = useWidthBreakpoint(breakpoints.smallMobile)
    const isMobile = useWidthBreakpoint(breakpoints.mobile)

    if(double && (isSmallMobile || (isMobile && numQuotaTokens > 1))){
        return <div style={{height: `${isSmallMobile ? numQuotaTokens > 1 ? 18 : 36 : 64}px`}}/>
    }

    return (
        <Container double={double}>
            <BackgroundArt src={Background} double={double}/>
            <AnimatedFigure src={Foreground} double={double}/>
        </Container>
    )
    
    
}

const Container = styled.div<{double: boolean}>`
    position: relative;
    display: flex;
    justify-content: center;

    ${Media.mobile}{
        margin: 32px 0 32px 0;
        ${({ double }) => double && css`
            margin: 0;
        `};
    }

    ${Media.smallMobile}{
        margin: 0;
    }
`

const BackgroundArt = styled.img<{double : boolean}>`

    ${({ double }) => double && css`
        height: 144px;
    `};

    ${({ double }) => !double && css`
        height: 192px;
    `};

    ${Media.mobile}{
        height: 144px !important;
    }

    ${Media.smallMobile}{
        height: 118px !important;
    }
`

const AnimatedFigure = styled.img<{double : boolean}>`
    position: absolute;

    animation: float 1.5s infinite;

    ${({ double }) => double && css`
        height: 112px;
        top: 16px;
        @keyframes float {
            0%  { transform: translate(0px, 0px);  }
            50%  { transform: translate(0px, -2px);  }
            100%  { transform: translate(0px, -0px);  }
        }
    `};

    ${({ double }) => !double && css`
        height: 150px;
        top: 22px;
        @keyframes float {
            0%  { transform: translate(0px, 0px);  }
            50%  { transform: translate(0px, -4px);  }
            100%  { transform: translate(0px, -0px);  }
        }
    `};

    ${Media.mobile}{
        height: 112px !important;
        top: 16px;
        @keyframes float {
            0%  { transform: translate(0px, 0px);  }
            50%  { transform: translate(0px, -2px);  }
            100%  { transform: translate(0px, -0px);  }
        }
    }

    ${Media.smallMobile}{
        height: 92px !important;
        top: 14px;
        @keyframes float {
            0%  { transform: translate(0px, 0px);  }
            50%  { transform: translate(0px, -2px);  }
            100%  { transform: translate(0px, -0px);  }
        }
    }
`