import { createSlice } from '@reduxjs/toolkit'
interface QuotaState {
    quotas: Record<string, string>
}

const initialState : QuotaState = {quotas: {}}

const quotasSlice = createSlice({
  name: 'quotas',
  initialState,
  reducers: {
      addQuota: (state, action) => {
          let newQuotas = {...state.quotas}
          newQuotas[action.payload.tokenID] = action.payload.amount
          return {
              ...state,
              quotas: newQuotas
          }
      },
      clearQuotas: () => {
          return initialState
      }
  }
})

export const { addQuota, clearQuotas } = quotasSlice.actions

export default quotasSlice.reducer