import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { NFT, NFT1155, tokenMap } from "../../../placeholders/tokens";
import { Media } from "../../../styles";
import checked from "../../../assets/icons/checked.svg";
import list from "../../../assets/icons/list.svg";
import { scrollbar } from "../../../styles/scrollbar";
import { useSearch } from "../../../hooks/useSearch";
import grid from "../../../assets/icons/grid.svg";
import { Modal } from "../../../components/Modal/Modal";
import { List, ListItem, SearchContainer, Title, } from "../../../components/TokensModal/TokensModal";
import { SearchInput } from "../../../components/TokensModal/SearchInput";
import { ButtonPrimary } from "../../../components/Buttons/Button";
import { CollectionButton } from "../../../components/Collections/CollectionButton";
import { CollectionGridItem } from "../../../components/Collections/CollectionGridItem";
import { FilterDropdown, NFTFilter, } from "../../../components/Collections/FilterDropdown";
import { useNFTSort } from "../../../hooks/useNFTSort";
import { BigNumber, Contract } from "ethers";
import { OCEAN_ADDRESS, TOUCAN_ADDRESS } from "../../../constants/addresses";
import { NFT_RARITY, WRAPPED_NFT_MAP } from "../../../constants/wrappedNFTs";
import { useProvider } from "wagmi";
import { OceanABI } from "../../../constants/ABI/OceanABI";
import { Spinner, StraightLoader } from "../../../components/Loaders";
import { useAppSelector } from "../../../store/hooks";
import { calculateWrappedTokenId, removeLeadingZeros, } from "../../../utils/ocean/utils";
import { buildNFTDisplays, getNFTs } from "../../../utils/nftHelpers";
import { Slider } from "../../../components/Slider/Slider";
import { ToggleSwitch } from "../../../components/ToggleSwitch/ToggleSwitch";

interface NFTSelectionModalProps {
  title: string;
  allSelectedNFTs: { [collection: string]: (NFT | NFT1155)[] };
  nftTokens?: NFT[];
  isInputToken: boolean;
  selectedCollection: any;
  updateSelectedNFTs?: any;
  nftSweepInputPrice?: null | string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onNFTSweepSelect?: (collection: string, items: NFT[]) => void;
  onClose?: () => void;
}

const NFTSelectionModal: React.FC<NFTSelectionModalProps> = ({
  allSelectedNFTs,
  nftTokens,
  isInputToken,
  updateSelectedNFTs,
  selectedCollection,
  nftSweepInputPrice,
  onNFTSweepSelect,
  onClose,
  showModal,
  setShowModal,
  title,
}) => {
  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);
  const provider = useProvider();

  const wrappedIDs = WRAPPED_NFT_MAP[ (selectedCollection.wrapped ? "" : "sh") + selectedCollection.symbol ];
  const zeroBalance = !userNFTBalances[selectedCollection.symbol] || userNFTBalances[selectedCollection.symbol]?.length == 0;
  const lowLiquidity = new Set();

  const [searchValue, setSearchValue] = useState("");
  const [viewType, setViewType] = useState<"list" | "grid">("grid");

  const [isAllSelected, setIsAllSelected] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const [showSlider, setShowSlider] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);

  const [nfts, setNFTs] = useState<NFT[]>([]);
  const [selectedItems, setSelectedItems] = useState<NFT[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<NFTFilter>({
    order: null,
    orderBy: null,
    label: "Sort by",
  });

  const shuffleArray = (array: any[]) => {
    const shuffledArray = [...array];
  
    for (let i = shuffledArray?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
  
    return shuffledArray;
  }
  
  const generateChunks = (nftIDs: string[]) => {
    const numChunks = 50;
    const sublistSize = Math.ceil(nftIDs?.length / numChunks);
  
    const chunks = Array(numChunks)
      .fill([])
      .map((_, i) => nftIDs.slice(i * sublistSize, (i + 1) * sublistSize));
  
    return chunks;
  };

  const initChunks = shuffleArray(generateChunks(Object.keys(wrappedIDs)));
  const [idChunks, setIdChunks] = useState<any[]>(initChunks);

  const [isLoading, setIsLoading] = useState(false);
  const [bottomLoading, setBottomLoading] = useState(false);
  const [hasMoreBottom, setHasMoreBottom] = useState(true);

  const toggleModal = () => {
    setShowModal(!showModal);
    setIdChunks(initChunks);
    setSearchValue("");
  };

  const filteredCollectionItems = useSearch(nfts, ["id"], searchValue);
  const sortedNFTs = useNFTSort(
    filteredCollectionItems,
    selectedFilter.order,
    selectedFilter.orderBy
  );

  const isItemSelected = (itemId: number) =>
    selectedItems && selectedItems.map(({ id }) => id).includes(itemId);

  const onClickItem = (item: NFT) => {
    const newSelectedItems = isItemSelected(item.id)
      ? selectedItems.filter(({ id }) => id !== item.id)
      : [...selectedItems, item];
    const newSliderValue = showSlider
      ? isItemSelected(item.id)
        ? sliderValue - 1
        : sliderValue + 1
      : sliderValue;
    setSelectedItems(newSelectedItems);
    setSliderValue(newSliderValue);
    if (showSlider && selectedCollection && onNFTSweepSelect) {
      onNFTSweepSelect(selectedCollection.symbol, newSelectedItems);
    }
  };

  const handleScroll = (e: any) => {
    if ( Math.floor(e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight ) {
      if (!isInputToken && hasMoreBottom) loadMoreBottom();
    }
  };

  const loadMoreBottom = () => {
    if (!bottomLoading) {
      setBottomLoading(true);
      getFractionalizedNFTs(idChunks).then((newFractionalizedNFTS: NFT[]) => {
        setNFTs(nfts.concat(newFractionalizedNFTS));
        setBottomLoading(false);
      });
    }
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const defaultSliderValue = Math.max(1, sliderValue);
    const newSelectedItems = sortedNFTs.slice(0, defaultSliderValue);
    setShowSlider(isChecked);

    if (isChecked) {
      if (selectedItems?.length === 0) {
        setSelectedItems(newSelectedItems);
        setSliderValue(defaultSliderValue);
      } else {
        setSliderValue(selectedItems?.length);
      }
      if (selectedCollection && onNFTSweepSelect) {
        onNFTSweepSelect(selectedCollection.symbol, newSelectedItems);
      }
    } else {
      if (selectedCollection && onNFTSweepSelect) {
        onNFTSweepSelect(selectedCollection.symbol, []);
      }
      setSelectedItems([]);
      setSliderValue(1);
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSliderValue = parseInt(event.target.value);
    const newSelectedItems = sortedNFTs.slice(0, newSliderValue);
    setSliderValue(newSliderValue);
    setSelectedItems(newSelectedItems);
    if (selectedCollection && onNFTSweepSelect) {
      onNFTSweepSelect(selectedCollection.symbol, newSelectedItems);
    }
  };

  const getFractionalizedNFTs = async (chunks: string[][]) => {
    const ocean = new Contract(OCEAN_ADDRESS, OceanABI, provider);

    let newFractionalizedNFTs: any[] = [];

    if (lowLiquidity.has(selectedCollection.address)) {
      const oceanNFTs = (
        await getNFTs(selectedCollection.fractionalizer, OCEAN_ADDRESS)
      ).filter((token) => token.balance == 1);
      oceanNFTs.forEach((oceanNFT: any) => {
        const oceanID = removeLeadingZeros(
          BigNumber.from(oceanNFT.tokenId).toHexString()
        );
        if (wrappedIDs[oceanID])
          newFractionalizedNFTs.push(wrappedIDs[oceanID][0]);
      });
      setHasMoreBottom(false);
    } else {
      while (chunks?.length) {
        const chunk = chunks.shift()!;
        const balances = await ocean.balanceOfBatch(
          chunk.map(() => selectedCollection.fractionalizer),
          chunk
        );
        for (let i = 0; i < chunk?.length; i++) {
          if (!balances[i].isZero()) {
            const nftID = wrappedIDs[chunk[i]][0];
            newFractionalizedNFTs.push(nftID);
          }
        }

        if (newFractionalizedNFTs?.length > 20) {
          break;
        }
      }

      setIdChunks(chunks);
      setHasMoreBottom(chunks?.length > 0);
    }

    return filterAlreadySelectedNFTs(
      await buildNFTDisplays(selectedCollection, newFractionalizedNFTs)
    );
  };

  const getUserNFTs = async () => {
    if (!zeroBalance) {
      return filterAlreadySelectedNFTs(
        await buildNFTDisplays(
          selectedCollection,
          userNFTBalances[selectedCollection.symbol]
        )
      );
    } else {
      return [];
    }
  };

  const filterAlreadySelectedNFTs = (nfts: NFT[]) => {
    for (const collectionID of Object.keys(allSelectedNFTs)) {
      const collection = tokenMap[collectionID];
      if (
        collection.address === selectedCollection.address &&
        collection.wrapped !== selectedCollection.wrapped
      ) {
        const alreadySelectedNFTs = allSelectedNFTs[collectionID]
          ? new Set(allSelectedNFTs[collectionID].map((nft) => nft.id))
          : new Set();
        return nfts.filter((nft) => !alreadySelectedNFTs.has(nft.id));
      }
    }
    return nfts;
  };

  const handleFilterChange = (filter: any) => {
    setSelectedFilter(filter);

    if (!isInputToken) {
      setSelectedItems([]);
      let newChunks: any;

      if (filter.orderBy == "id") {
        newChunks = generateChunks(
          filter.order == "asc"
            ? Object.keys(wrappedIDs)
            : Object.keys(wrappedIDs).reverse()
        );
      } else {
        const raritySortedIds: any = Object.entries(
          NFT_RARITY[selectedCollection.address]
        )
          .sort(
            ([, valueA]: [string, number], [, valueB]: [string, number]) =>
              valueA - valueB
          )
          .map((pair) =>
            calculateWrappedTokenId(
              selectedCollection.address,
              parseInt(pair[0])
            )
          );
        newChunks = generateChunks(
          filter.order == "asc" ? raritySortedIds : raritySortedIds.reverse()
        );
      }

      setIdChunks(newChunks);
      setIsLoading(true);
      getFractionalizedNFTs(newChunks).then((newFractionalizedNFTS) => {
        setNFTs(newFractionalizedNFTS);
        setIsLoading(false);
      });

      setSliderValue(1);
      setShowSlider(false);
    }
  };

  useEffect(() => {
    if(nftTokens && nftTokens?.length == 0){
        setIdChunks(initChunks)
        setSelectedItems([])
    }
  }, [nftTokens])

  useEffect(() => {
    setIsAllSelected(
      selectedItems?.length > 0 && selectedItems?.length == sortedNFTs?.length
    );
    setConfirmVisible(selectedItems?.length > 0);
  }, [selectedItems, sortedNFTs]);

  useEffect(() => {
    if (showModal) {
      setIsLoading(!nftTokens);
      setIdChunks(initChunks);
      setSearchValue("");
      const getNFTsFn = isInputToken ? getUserNFTs : getFractionalizedNFTs;
      getNFTsFn(idChunks || []).then((fetchedNFTs) => {
        setNFTs(filterAlreadySelectedNFTs(fetchedNFTs));
        setIsLoading(false);
      });
    }
  }, [showModal]);

  return (
    <StyledModal
      isVisible={showModal}
      onClose={() => { onClose && onClose(); toggleModal(); }}
      title={title}
    >
      <SearchContainer>
        <SearchInput
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder="Type NFT #..."
        />
      </SearchContainer>
      <FiltersContainer>
        <FilterDropdown
          onChange={(filter) => handleFilterChange(filter)}
          list={[
            { orderBy: "id", order: "asc", label: "ID: Low to High" },
            { orderBy: "id", order: "desc", label: "ID: High to Low" },
            { orderBy: "rarity", order: "asc", label: "Rarity: Low to High" },
            { orderBy: "rarity", order: "desc", label: "Rarity: High to Low" },
          ]}
          value={selectedFilter}
        />
        {isInputToken ? (
          <MaxButton
            onClick={() => {
              setSelectedItems(isAllSelected ? [] : sortedNFTs);
            }}
          >
            {isAllSelected ? "Unselect All" : "Select All"}
          </MaxButton>
        ) : (
          <></>
        )}
        <FiltersButtons>
          <FilterButton
            data-testid="list-view-btn"
            active={viewType === "list"}
            onClick={() => setViewType("list")}
          >
            <img src={list} alt="List" />
          </FilterButton>
          <FilterButton
            data-testid="grid-view-btn"
            active={viewType === "grid"}
            onClick={() => setViewType("grid")}
          >
            <img src={grid} alt="Grid" />
          </FilterButton>
        </FiltersButtons>
      </FiltersContainer>
      {!isInputToken && (
        <ToggleAndSliderContainer>
          <ToggleSwitch
            dataTestId={`sweep-toggle-${showSlider ? "on" : "off"}`}
            isChecked={showSlider}
            onChange={handleToggleChange}
            labelLeft={true}
          >
            Sweep
          </ToggleSwitch>
          {showSlider && (
            <Slider
              value={sliderValue}
              max={sortedNFTs?.length}
              onChange={(event) => handleSliderChange(event)}
              isInsideNFTsSwiperSelect={true}
            />
          )}
        </ToggleAndSliderContainer>
      )}
      {isLoading ? (
        <CircleContainer>
          <Spinner />
        </CircleContainer>
      ) : sortedNFTs?.length == 0 ? (
        <Title style={{ margin: "auto" }}>No NFTs Found</Title>
      ) : viewType === "list" ? (
        <CollectionList
          onScroll={handleScroll}
          confirmVisible={selectedItems?.length > 0}
        >
          {sortedNFTs.map((item, index) => (
            <ListItem key={index}>
              <CollectionButton
                dataTestId={`nft-list-item-${index}`}
                icon={item.image}
                iconRight={isItemSelected(item.id) ? checked : ""}
                title={"#" + item.id}
                aboveTitle={item.name}
                subtitle={`Rarity: ${item.rarity}`}
                selected={isItemSelected(item.id)}
                onClick={() => onClickItem(item)}
              />
            </ListItem>
          ))}
          {bottomLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <StraightLoader />
            </div>
          )}
        </CollectionList>
      ) : (
        <>
          <Grid
            onScroll={handleScroll}
            confirmVisible={selectedItems?.length > 0}
          >
            {" "}
            {sortedNFTs.map((item, index) => (
              <CollectionGridItem
                dataTestId={`nft-item-${index}`}
                key={index}
                icon={item.image}
                title={"#" + item.id}
                aboveTitle={item.name}
                subtitle={`Rarity: ${item.rarity}`}
                selected={isItemSelected(item.id)}
                onClick={() => onClickItem(item)}
              />
            ))}
          </Grid>
          {bottomLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <StraightLoader />
            </div>
          )}
        </>
      )}
      {confirmVisible && (
        <ConfirmButton
          data-testid="nft-swiper-select-confirm-btn"
          onClick={() => {
            if (!showSlider) {
              updateSelectedNFTs(
                selectedCollection.symbol,
                selectedItems ?? []
              );
            }
            toggleModal();
          }}
        >
          Confirm
          {showSlider &&
            nftSweepInputPrice &&
            nftSweepInputPrice.split(" ")[0] !== "0" && (
              <>
                {"  |  "}
                {nftSweepInputPrice}
              </>
            )}
        </ConfirmButton>
      )}
    </StyledModal>
  );
};

export default NFTSelectionModal;

const StyledModal = styled(Modal)`
  height: 580px;
  max-width: 620px;

  ${Media.mobile} {
    height: 90%;
    max-width: 100%;
    margin-top: 9.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const CollectionList = styled(List)<{ confirmVisible: boolean }>`
  margin-top: 16px;
  padding: 3px 12px 2px 3px;

  ${({ confirmVisible }) =>
    confirmVisible &&
    css`
      padding-bottom: 80px;
    `};
`;

const Grid = styled.div<{ confirmVisible: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - calc(3 * 12px / 4)));
  gap: 16px 12px;
  margin-top: 16px;
  padding: 3px 12px 12px 3px;
  overflow-y: auto;
  ${scrollbar()}

  ${Media.mobile} {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    margin: 16px auto 0;
  }

  ${({ confirmVisible }) =>
    confirmVisible &&
    css`
      padding-bottom: 80px;
    `};
`;

const MaxButton = styled.button`
  margin-left: 12px;
  border: none;
  border: solid 1px #7d7d97;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0);
  color: #7d7d97;
  font-size: 10px;
  font-weight: 250;
  line-height: 17px;

  :hover {
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    color: #000e47;
    border: solid 1px #000e47;
  }
`;

const FiltersButtons = styled.div`
  display: flex;
  gap: 0 11px;
  margin-left: auto;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
`;

const ConfirmButton = styled(ButtonPrimary)`
  position: absolute;
  bottom: 12px;
  width: 100%;
  height: 62px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  //box-shadow: 0px 4px 24px rgba(42, 212, 244, 0.4);
`;

const FilterButton = styled(Button)<{ active?: boolean | undefined }>`
  opacity: ${(props) => (props.active ? "1" : "0.2")};

  &:hover {
    opacity: ${(props) => (props.active ? "1" : "0.4")};
  }
`;

const CircleContainer = styled.div`
  width: 74px;
  height: 74px;
  margin: auto;
`;

const ToggleAndSliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 16px;
  padding-left: 5px;
`;
