import React from "react";
import styled from "styled-components";
import { tokens, shellTokens, nftCollections, isShellToken, isNFTCollection, lbpTokens, tokenMap } from "../placeholders/tokens";

interface LogoProps {
  size?: number;
  src?: string;
  alt?: string;
  className?: string;
}

const Logo = styled.img<LogoProps>`
  max-width: 100%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

const nftAddresses = new Set<string>();
for (const collection of nftCollections) {
  nftAddresses.add(collection.address);
}

const createTokenLogo = (token : any, iconSize: number) => {
  const tokenID = isShellToken(token) ? token.name : token.symbol
  const logo = ({
    alt = tokenID,
    className = "",
    src = token.icon,
    size = iconSize,
  }: LogoProps) => (
    <Logo src={src} alt={alt} size={size} className={className} />
  );

  return styled(logo)`
    margin-right: 18px;
    border-radius: ${isNFTCollection(token) ? 0 : 50}%;
    width: ${isShellToken(token) || nftAddresses.has(token.address) ? '50px' : 'inherit'}
  `;
};

const tokenLogos : Record<string, any> = {}

tokens.forEach((token) => {
    tokenLogos[token.symbol] = createTokenLogo(token, 83)
})

shellTokens.forEach((shellToken) => {
    tokenLogos[shellToken.name] = createTokenLogo(shellToken, 50)
})

nftCollections.forEach((collection) => {
    tokenLogos[collection.symbol] = createTokenLogo(collection, 50)
})

lbpTokens.forEach((lbpToken) => {
    tokenLogos[lbpToken.symbol] = createTokenLogo(lbpToken, 83)
    tokenLogos[lbpToken.poolName] = createTokenLogo(tokenMap[lbpToken.poolName], 50)
})

export { tokenLogos };
